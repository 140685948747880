<template>
<div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
        <div>
            <div class="p-2">
                <div class="pos__tabs nav nav-tabs justify-between" role="tablist">
                    <h2 class="text-lg font-medium mr-auto">Invoice  Transactions</h2>
                    <div>

                        <router-link to="/stock/outward-stock/release" href="javascript:;" class="btn btn-primary btn-sm mx-2 py-2">
                            Release Stock
                        </router-link>
                      </div>
                </div>
            </div>
        </div>
        <div class="grid grid-cols-12 gap-x-2 mt-5">
            <div class="col-span-6 md:col-span-3 xl:col-span-2">
                <label for="input-quantity" class="form-label">Invoice NO</label>
                <input id="input-quantity" class="form-control" placeholder="Invoice NO" autocomplete="off" v-model="outward_filters.request_code" type="text">
            </div>
            <div class="col-span-6 md:col-span-3 xl:col-span-2">
                <label for="pos-form-2" class="form-label">Select Customer</label>
                <TomSelect v-model="outward_filters.party_id" :options="{
                  placeholder: 'Select Product'
                }" class="w-full" name="Product" id="pos-form-2">
                    <option value="0">Select Company</option>
                    <option v-for="item in page_data.party_details" :key="item.id" :value="item.id">{{ item.companey_name}}</option>
                </TomSelect>
            </div>
            <div class="col-span-3 md:col-span-2 xl:col-span-1">
                <div class="mt-5">
                    <button @click="resetStockFilters" class="btn btn-secondary w-full mt-2">
                        Reset
                    </button>
                </div>
            </div>
            <div class="col-span-3 md:col-span-2 xl:col-span-1">
                <div class="mt-5">
                    <button @click="initTabulator()" class="btn btn-primary w-full mt-2">
                        Generate
                    </button>
                </div>
            </div>
        </div>
        <div class="overflow-x-auto scrollbar-hidden">
            <div id="tabulator" ref="tableRef" class="mt-5 table-report table-report--tabulator"></div>
        </div>
    </div>
    <!-- END: HTML Table Data -->
</div>
</template>

<script>
/* eslint-disable */
import {
    defineComponent,
    ref,
    reactive,
    toRefs,
    onMounted,
    inject,
    computed,
    watch
} from 'vue'
import xlsx from 'xlsx'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import axios from 'axios'
import {
    BASE_URL
} from '@/constant/config.js'
import cash from 'cash-dom'
import moment from 'moment'
import {
    helper as $h
} from '@/utils/helper'
import router from '@/router'
import store from '@/store'

export default defineComponent({
    setup() {
        const swal = inject('$swal')
        const tableRef = ref()
        const tabulator = ref()
        const tableRefOW = ref()
        const tabulatorOW = ref()
        const userDetails = computed(() => store.state.user.currentUser)
        const page_data = reactive({
            stock_type: 2,
            count_ids: 1,
            watt_details: [],
            party_details: [],
            purchase_item: [],
            request_loading: false,
            purchase_loading: false,
            request_details: {
                request_item: [],
                request_products: [],
                new_quantity: ''
            },
            product_details: {
                product_detail: [],
                purchase_details: [],
                total: [],
                selected_quantity: 0,
                required_quantity: 0,
                limit_touch: false,
            },
            duty_assigned_arr: [],
            message: '',
            ref_no: '',
            deliver_id: ''
        })

        const outward_filters = reactive({
            party_id: "0",
            watt_id: "0",
            pannel_type: "0",
            status: "0",
            request_code: "",
            invoice_no: "",
            s_date: moment().startOf('month').format('YYYY-MM-DD'),
            e_date: moment().format('YYYY-MM-DD'),
            page_size: '1'
        })
        const print_filters = reactive({
            party_id: "0",
            watt_id: "0",
            pannel_type: "0",
            status: "0",
            request_code: "",
            invoice_no: "",
            s_date: moment().startOf('month').format('YYYY-MM-DD'),
            e_date: moment().format('YYYY-MM-DD')
        })

        const initTabulator = () => {

          print_filters.watt_id = outward_filters.watt_id
          print_filters.pannel_type = outward_filters.pannel_type
          print_filters.status = outward_filters.status
          print_filters.request_code = outward_filters.request_code
          print_filters.s_date = outward_filters.s_date
          print_filters.e_date = outward_filters.e_date

          setTimeout(() => {

              tabulator.value = new Tabulator(tableRef.value, {
                  ajaxURL: BASE_URL + 'api/get_sell_details',
                  ajaxConfig: {
                      method: "GET", //set request type to Position
                      headers: {
                          "Content-type": 'application/json; charset=utf-8', //set specific content type
                          "Authorization": "Bearer " + localStorage.getItem("token")
                      },
                  },
                  ajaxParams: {
                      "party_id": print_filters.party_id == "0" ? "" : window.btoa(print_filters.party_id),
                      "watt_id": print_filters.watt_id == "0" ? "" : window.btoa(print_filters.watt_id),
                      "pannel_type": print_filters.pannel_type == "0" ? "" : window.btoa(print_filters.pannel_type),
                      "status": print_filters.status == "0" ? "" : window.btoa(print_filters.status),
                      "request_code": print_filters.request_code == "" ? "" : window.btoa(print_filters.request_code),
                      "s_date": "",
                      // "s_date": print_filters.s_date == "" ? "" : window.btoa(print_filters.s_date),
                      "e_date": "",
                      // "e_date": print_filters.e_date == "" ? "" : window.btoa(print_filters.e_date),
                  },
                  pagination: 'remote',
                  paginationSize: 20,
                  // renderHorizontal:"virtual",
                  paginationSizeSelector: [20, 30, 40, 60],
                  ajaxFiltering: true,
                  ajaxSorting: true,
                  headerSort: false,
                  printAsHtml: true,
                  printStyled: true,
                  layout: 'fitColumns',
                  responsiveLayout: 'collapse',
                  placeholder: 'No matching records found',
                  columns: [{
                          title: 'Invoice',
                          minWidth: 150,
                          responsive: 0,
                          field: 'invoice_no',
                          hozAlign: 'left',
                          vertAlign: 'middle',
                          print: false,
                          download: false,
                          formatter(cell) {
                            if (cell.getData().active_status == 1) {

                              const a = cash(`<div class="flex lg:justify-center items-center">
                                      ` + (cell.getData().invoice_no) + `

                                  </div>`)
                              cash(a).on('click', function () {
                                router.push({ path: '/stock/outward-stock/release/' + window.btoa(cell.getData().id) })
                              })

                              return a[0]
                            }
                          }
                      },{
                          title: 'COmpany Name',
                          minWidth: 150,
                          responsive: 0,
                          field: 'company_name',
                          hozAlign: 'left',
                          vertAlign: 'middle',
                          print: false,
                          download: false,
                          formatter: "textarea",
                      },
                      {
                          title: 'Quantity',
                          minWidth: 100,
                          responsive: 0,
                          field: 'quantity',
                          hozAlign: 'left',
                          vertAlign: 'middle',
                          print: false,
                          download: false
                      },
                      {
                          title: 'Sell Date',
                          minWidth: 100,
                          responsive: 0,
                          field: 'sell_date',
                          hozAlign: 'left',
                          vertAlign: 'middle',
                          print: false,
                          download: false
                      },
                      {
                          title: 'Status',
                          minWidth: 100,
                          field: 'active_status',
                          hozAlign: 'center',
                          vertAlign: 'middle',
                          print: false,
                          download: false,
                          formatter(cell) {
                              if (cell.getData().active_status == 1) {

                                  return '<div class="flex items-center lg:justify-center text-theme-1">Sold</div>'

                              } else if (cell.getData().active_status == 2) {

                                  return '<div class="flex items-center lg:justify-center text-theme-3">Sold</div>'

                              } else if (cell.getData().active_status == 3) {

                                  return '<div class="flex items-center lg:justify-center text-theme-6">Cancelled</div>'

                              }
                          }
                      },
                      {
                          title: 'Entry Date',
                          minWidth: 100,
                          responsive: 0,
                          field: 'created_at',
                          hozAlign: 'left',
                          vertAlign: 'middle',
                          print: false,
                          download: false
                      },

                  ],
                  renderComplete() {
                      feather.replace({
                          'stroke-width': 1.5
                      })
                  }
              })

              sessionStorage.setItem('stock_department_requset_filters', JSON.stringify(outward_filters))
          }, 50);

        }
        // Get Product Details

        // Get Outward Party Details
        const get_party_details = async () => {

          let promise_url = "api/master/dp_outward_party";
          let params = {}

          let promise = axios({
            url: promise_url,
            method: "POST",
            data: params,
            baseURL: BASE_URL,
            headers: { Authorization: "Bearer " + localStorage.getItem("token") },
            config: { headers: { Accept: "application/json" } }
          });
          return promise
          .then(result => result.data)
          .then(data => {
            page_data.party_details = data.items
          })
          .catch(_error => {
            page_data.party_details = []

          });
        }

        // On reset filter
        const resetStockFilters = () => {
            outward_filters.party_id = "0"
            outward_filters.watt_id = "0"
            outward_filters.pannel_type = "0"
            outward_filters.status = "0"
            outward_filters.request_code = ""
            outward_filters.s_date = moment().startOf('month').format('YYYY-MM-DD')
            outward_filters.e_date = moment().format('YYYY-MM-DD')
            outward_filters.page_size = 1

            initTabulator()
        }
        // Export
        const onExportCsv = () => {
            tabulator.value.download('csv', 'data.csv')
        }

        const onExportJson = () => {
            tabulator.value.download('json', 'data.json')
        }

        const onExportXlsx = () => {
            const win = window
            win.XLSX = xlsx
            tabulator.value.download('xlsx', 'data.xlsx', {
                sheetName: 'Products'
            })
        }

        const onExportHtml = () => {
            tabulator.value.download('html', 'data.html', {
                style: true
            })
        }

        // Print
        const onPrint = () => {
            tabulator.value.print()
        }

        onMounted(() => {

            get_party_details()
            initTabulator()
            // initTabulatorOW()
        })

        return {
            tableRef,
            page_data,
            outward_filters,
            print_filters,
            onExportCsv,
            onExportJson,
            onExportXlsx,
            onExportHtml,
            onPrint,
            initTabulator,
            resetStockFilters,
            userDetails
        }
    }
})
</script>

<style scoped>
.tableFixHead {
    overflow-y: auto;
    /* make the table scrollable if height is more than 200 px  */
    max-height: 700px;
    /* gives an initial height of 200px to the table */
}

.tableFixHead thead {
    position: sticky;
    /* make the table heads sticky */
    top: 0px;
    /* table head will be placed from the top of the table and sticks to it */
}
</style>
