<template>
  <div>

    <!-- BEGIN: HTML Product Details -->
    <div class="intro-y box p-5 mt-5">
      <h2 class="text-lg font-medium mr-auto">Material Wise Stock Report</h2>
      <!-- Filter For Product Details -->
      <div class="grid grid-cols-12 gap-x-2 mt-5">
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <label for="pos-form-2" class="form-label">Select Material</label>
          <TomSelect
            v-model="stock_filters.product_id"
              :options="{
                placeholder: 'Select Material'
              }"
              class="w-full"
              name="Material"
              id="pos-form-2"
              @change="materialDetails"
          >
          <option value="0">Select Material</option>
          <option v-for="item in pagedata.products" :key="item.id" :value="item.id">{{ item.product_name}}</option>
          </TomSelect>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <label for="pos-form-2" class="form-label">Select Specification</label>
          <TomSelect
            v-model="stock_filters.material_id"
              :options="{
                placeholder: 'Select Specification'
              }"
              class="w-full"
              name="Specification"
              id="pos-form-2"
          >
          <option value="0">Select Specification</option>
          <option v-for="item in pagedata.materials" :key="item.id" :value="item.id">{{ item.description}}</option>
          </TomSelect>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <label for="pos-form-2" class="form-label">Product Type</label>
          <TomSelect
            v-model="stock_filters.product_type"
              :options="{
                placeholder: 'Select Product Type'
              }"
              class="w-full"
              name="Product Type"
              id="pos-form-2"
          >
          <option value="0">Select Product Type</option>
          <option value="1">Poly</option>
          <option value="2">MBB</option>
          </TomSelect>
        </div>
        <div class="col-span-3 md:col-span-2 xl:col-span-1">
          <div class="mt-5">
            <button
            @click="resetStockFilters"
            class="btn btn-secondary w-full mt-2">
              Reset
            </button>
          </div>
        </div>
        <div class="col-span-3 md:col-span-2 xl:col-span-1">
          <div class="mt-5">
            <button
            @click="generateStock"
            class="btn btn-primary w-full mt-2">
              Generate
            </button>
          </div>
        </div>
      </div>

      <div class="overflow-x-auto scrollbar-hidden">
        <div>
          <!-- BEgin: Process Report Start-->
          <div id="responsive-table" class="p-5" v-if="pagedata.loading">
            <div class="preview">
              <div class="overflow-x-auto">
                <div
                  class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center"
                >
                  <LoadingIcon icon="bars" class="w-8 h-8" />
                  <div class="text-center text-xs mt-2">Getting Consumptions</div>
              </div>
              </div>
            </div>
          </div>
          <div v-else>

            <div class="border mt-5 p-0 shadow-xl">
              <div class="overflow-x-auto m-0 p-0 tableFixHead">
                <table class="w-full text-center text-xs">
                  <thead>
                    <tr class="bg-theme-14">
                      <th class="p-2"></th>
                      <!-- <th class="p-2"></th> -->
                      <th class="p-2"></th>
                      <th class="p-2"></th>
                      <th class="py-2 px-2"></th>
                      <th class="border border-t-0 dark:border-dark-5 py-2 px-2" colspan="5">Open Order Quantity</th>
                      <th class="border border-t-0 dark:border-dark-5 py-2 px-2" colspan="5">In Order Request</th>
                      <th class="border border-t-0 dark:border-dark-5 py-2 px-2" colspan="5">Total Material</th>
                      <th class="border border-t-0 dark:border-dark-5 py-2 px-2" colspan="4">Order Remaining ({{ pagedata.total_modules}})</th>
                    </tr>
                    <tr class="bg-theme-14">
                      <td class="p-2">#</td>
                      <td class="p-2">Material</td>
                      <!-- <td class="p-2">Specification</td> -->
                      <td class="p-2">Type</td>
                      <td class="border-r dark:border-dark-5 p-2">UQC</td>
                      <td class="border dark:border-dark-5 p-2" rowspan="2">Total</td>
                      <td class="border-r dark:border-dark-5 p-2" colspan="2">Duty Paid</td>
                      <td class="border-r dark:border-dark-5 p-2" colspan="2">Duty Pending</td>
                      <td class="border dark:border-dark-5 p-2" rowspan="2">Total</td>
                      <td class="border-r dark:border-dark-5 p-2" colspan="2">Duty Paid</td>
                      <td class="border-r dark:border-dark-5 p-2" colspan="2">Duty Pending</td>
                      <td class="border dark:border-dark-5 p-2" rowspan="2">Total</td>
                      <td class="border-r dark:border-dark-5 p-2" colspan="2">Duty Paid</td>
                      <td class="border-r dark:border-dark-5 p-2" colspan="2">Duty Pending</td>
                      <td class="border dark:border-dark-5 p-2" rowspan="2">Reuired QTY</td>
                      <td class="border dark:border-dark-5 p-2" rowspan="2">Need To Prepare</td>
                      <td class="border dark:border-dark-5 p-2" rowspan="2">Porcessable Quantity</td>
                      <td class="border dark:border-dark-5 p-2" rowspan="2">Selected Quantity</td>

                    </tr>
                    <tr class="bg-theme-14">
                      <td class="border-b dark:border-dark-5 p-2"></td>
                      <!-- <td class="border-b dark:border-dark-5 p-2"></td> -->
                      <td class="border-b dark:border-dark-5 p-2"></td>
                      <td class="border-b dark:border-dark-5 p-2"></td>
                      <td class="border-b dark:border-dark-5 py-2 px-2"></td>
                      <!-- <td class="border dark:border-dark-5 py-2 px-1"></td> -->
                      <td class="border dark:border-dark-5 py-2 px-1">OOC Done</td>
                      <td class="border dark:border-dark-5 py-2 px-1">OOC Pending</td>
                      <td class="border dark:border-dark-5 py-2 px-1">Duty Pending</td>
                      <td class="border dark:border-dark-5 py-2 px-1">Duty Request</td>
                      <!-- <td class="border dark:border-dark-5 py-2 px-1"></td> -->
                      <td class="border dark:border-dark-5 py-2 px-1">OOC Done</td>
                      <td class="border dark:border-dark-5 py-2 px-1">OOC Pending</td>
                      <td class="border dark:border-dark-5 py-2 px-1">Duty Pending</td>
                      <td class="border dark:border-dark-5 py-2 px-1">Duty Request</td>
                      <!-- <td class="border dark:border-dark-5 py-2 px-1"></td> -->
                      <td class="border dark:border-dark-5 py-2 px-1">OOC Done</td>
                      <td class="border dark:border-dark-5 py-2 px-1">OOC Pending</td>
                      <td class="border dark:border-dark-5 py-2 px-1">Duty Pending</td>
                      <td class="border dark:border-dark-5 py-2 px-1">Duty Request</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="hover:bg-gray-200 p-0" :class="{'bg-gray-100': (index % 2) == 0}" v-for="(item, index) in pagedata.product_items" :key="index">
                      <td class="border-b py-2">{{ index + 1 }}</td>
                      <td class="border-b py-2">{{ item.product_name }}</td>
                      <!-- <td class="border-b py-2">{{ item.description }}</td> -->
                      <td class="border-b py-2">{{ item.product_type == 1 ? 'Poly' : (item.product_type == 2 ? 'MBB' : (item.product_type == 3 ? 'Both' : '-')) }}</td>
                      <td class="border-b border-r py-2">{{ item.different_uqc == 1 ? item.second_uqc_name : item.first_uqc_name }}</td>
                      <td class="border-b border-r py-2">{{ (item.cr_qty ? $h.myFloat(item.cr_qty) : '-') }}</td>
                      <td class="border-b py-2">{{ (item.cr_qty_paid ? $h.myFloat(item.cr_qty_paid - (item.cr_qty_ooc_pending ? item.cr_qty_ooc_pending : 0)) : '-') }}</td>
                      <td class="border-b border-r py-2">{{ (item.cr_qty_ooc_pending ? $h.myFloat(item.cr_qty_ooc_pending) : '-') }}</td>
                      <td class="border-b py-2">{{ (item.cr_qty_pending ? $h.myFloat(item.cr_qty_pending) : '-') }}</td>
                      <td class="border-b border-r py-2">{{ (item.cr_qty_duty_request ? $h.myFloat(item.cr_qty_duty_request) : '-') }}</td>
                      <td class="border-b border-r py-2">{{ (item.hold_qty ? $h.myFloat(item.hold_qty) : '-') }}</td>
                      <td class="border-b py-2">{{ (item.hold_qty_paid ? $h.myFloat(item.hold_qty_paid - (item.hold_qty_ooc_pending ? item.hold_qty_ooc_pending : 0)) : '-') }}</td>
                      <td class="border-b border-r py-2">{{ (item.hold_qty_ooc_pending ? $h.myFloat(item.hold_qty_ooc_pending) : '-') }}</td>
                      <td class="border-b py-2">{{ (item.hold_qty_pending ? $h.myFloat(item.hold_qty_pending) : '-') }}</td>
                      <td class="border-b border-r py-2">{{ (item.hold_qty_duty_request ? $h.myFloat(item.hold_qty_duty_request) : '-') }}</td>
                      <td class="border-b border-r py-2">{{ $h.myFloat((item.cr_qty ? $h.myFloat(item.cr_qty) : 0) + (item.hold_qty ? $h.myFloat(item.hold_qty) : 0)) }}</td>
                      <td class="border-b py-2">{{ $h.myFloat((item.cr_qty_paid ? $h.myFloat(item.cr_qty_paid) : 0) + (item.hold_qty_paid ? $h.myFloat(item.hold_qty_paid) : 0) - (item.hold_qty_ooc_pending ? item.hold_qty_ooc_pending : 0) - (item.cr_qty_ooc_pending ? item.cr_qty_ooc_pending : 0)) }}</td>
                      <td class="border-b border-r py-2">{{ $h.myFloat((item.cr_qty_ooc_pending ? item.cr_qty_ooc_pending : 0) + (item.hold_qty_ooc_pending ? item.hold_qty_ooc_pending : 0)) }}</td>
                      <td class="border-b py-2 border-r">{{ $h.myFloat((item.cr_qty_pending ? item.cr_qty_pending : 0) + (item.hold_qty_pending ? item.hold_qty_pending : 0)) }}</td>
                      <td class="border-b border-r py-2">{{ $h.myFloat((item.cr_qty_duty_request ? item.cr_qty_duty_request : 0) + (item.hold_qty_duty_request ? item.hold_qty_duty_request : 0)) }}</td>
                      <td class="border-b border-r py-2">{{ (item.required_quantity ? parseFloat(item.required_quantity).toFixed(0) : '-') }}</td>
                      <td class="border-b border-r py-2">{{ (item.need_to_prepare ? parseFloat(item.need_to_prepare).toFixed(0) : '-') }}</td>
                      <td class="border-b border-r py-2">{{ (item.cr_qty ? parseFloat(item.cr_qty).toFixed(0) : '-') }}</td>
                      <td class="border-b border-r py-2">{{ (item.hold_qty ? parseFloat(item.hold_qty).toFixed(0) : '-') }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div v-if="pagedata.product_items.length == 0" class="text-md font-medium mr-auto text-center my-8">
                Records Not Found
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: HTML Product Details -->
  </div>

</template>

<script>
/* eslint-disable */
import { defineComponent, reactive,onMounted, inject } from 'vue'
import axios from 'axios'
import { BASE_URL } from '@/constant/config.js'

export default defineComponent({
  setup() {
    const pagedata = reactive({
      materials: [],
      products: [],
      product_items: [],
      total_modules: "",
      loading: false,
    });

    // Stock Filters
    const stock_filters = reactive({
      product_id: '0',
      material_id: '0',
      product_type: '0'
    })


    // Generate Stock Report Details
    const generateStock = async () => {

      const params = {

        "product_id": stock_filters.product_id != "0" ? window.btoa(stock_filters.product_id) : "",
        "material_id": stock_filters.material_id != "0" ? window.btoa(stock_filters.material_id) : "",
        "product_type": stock_filters.product_type != "0" ? window.btoa(stock_filters.product_type) : "",
        };

        pagedata.loading = true
      let promise = axios({
        url: "api/stock_product_details",
        method: "POST",
        data: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          pagedata.product_items = data.items
          pagedata.total_modules = ""
          pagedata.loading = false

        })
        .catch(_error => {

          pagedata.product_items = []
          pagedata.total_modules = ""
          pagedata.loading = false

        });

    }

    // Get Product Details
    const productDetails = async () => {

      const params = {
      };

      let promise = axios({
        url: "api/master/dp_product",
        method: "POST",
        data: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          pagedata.products = data.items

        })
        .catch(_error => {

          pagedata.products = []

        });
    }

    // Get Material Details
    const materialDetails = async () => {

      const params = {
        product_id: stock_filters.product_id != "0" ? window.btoa(stock_filters.product_id) : ""
      };

      let promise = axios({
        url: "api/master/get_details_for_tax",
        method: "POST",
        data: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          pagedata.materials = data.items

        })
        .catch(_error => {

          pagedata.materials = []

        });
    }

    // Reset Stock Details Filter
    const resetStockFilters = () => {

      stock_filters.product_id = '0'
      stock_filters.material_id = '0'
      stock_filters.product_type = '0'

      generateStock()

    }

    // Mount Functions
    onMounted(() => {
      productDetails();
      materialDetails();
      generateStock();
    });
    return {
      pagedata,stock_filters,
      resetStockFilters,
      generateStock,materialDetails
    };
  }
})
</script>
<style scoped>
.tableFixHead {
        overflow-y: auto; /* make the table scrollable if height is more than 200 px  */
        max-height: 650px; /* gives an initial height of 200px to the table */
      }
      .tableFixHead thead {
        position: sticky; /* make the table heads sticky */
        top: 0px; /* table head will be placed from the top of the table and sticks to it */
      }
      </style>

