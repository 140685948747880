<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-3">
      <h2 class="text-lg font-medium mr-auto">Recent Activities</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">

        <!-- <router-link :to="{ name: 'dta-purchase-view-create' }" class="btn btn-primary shadow-md ml-3">Add
          Purchase</router-link> -->

      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5">
      <div class="grid grid-cols-12 gap-x-2">
        <div class="col-span-6 md:col-span-3 xl:col-span-2" v-if="user_details.user_type == 1">
          <!-- <label for="pos-form-2" class="form-label">Select User</label> -->
          <TomSelect v-model="formData.action_user" :options="{
          placeholder: 'Select Material'
        }" @change="initTabulator" class="w-full" name="uqc" id="pos-form-2">
            <option value="0">Select User</option>
            <option v-for="item in pagedata.users" :key="item.id" :value="item.id">{{ item.first_name }} {{ item.last_name }}</option>
          </TomSelect>
        </div>
        <!-- <div class="col-span-6 md:col-span-3 xl:col-span-2" v-if="user_details.user_type == 1">
          <label for="pos-form-2" class="form-label">Transaction Type</label>
          <TomSelect v-model="formData.transaction_type" :options="{
          placeholder: 'transaction Type'
        }" @change="initTabulator" class="w-full" name="uqc" id="pos-form-2">
            <option value="0">Select User</option>
            <option v-for="item in pagedata.transaction_types" :key="item.id" :value="item.id">{{ item.action_name }} {{ item.action_type }}</option>
          </TomSelect>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <label for="pos-form-2" class="form-label">Select Material</label>
          <TomSelect v-model="formData.product_id" :options="{
          placeholder: 'Select Material'
        }" @change="initTabulator" class="w-full" name="uqc" id="pos-form-2">
            <option value="0">Select Material</option>
            <option v-for="item in pagedata.products" :key="item.id" :value="item.id">{{ item.product_name }}</option>
          </TomSelect>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <label for="pos-form-2" class="form-label">Select Specification</label>
          <TomSelect v-model="formData.material_id" @change="initTabulator" :options="{
          placeholder: 'Specification'
        }" class="w-full" name="Specification" id="pos-form-2">
            <option value="0">Select Specification</option>
            <option v-for="item in pagedata.materials" :key="item.id" :value="item.id">{{ item.description }}</option>
          </TomSelect>
        </div>
        <div class="col-span-3 md:col-span-2 xl:col-span-1">
          <div class="mt-5">
            <button @click="resetFilters" class="btn btn-secondary w-full mt-2">
              Reset
            </button>
          </div>
        </div>
        <div class="col-span-3 md:col-span-2 xl:col-span-1">
          <div class="mt-5">
            <button @click="initTabulator" class="btn btn-primary w-full mt-2">
              Generate
            </button>
          </div>
        </div> -->
      </div>
      <div class="overflow-x-auto scrollbar-hidden">
        <div id="tabulator" ref="tableRef" class="mt-5 table-report table-report--tabulator"></div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>

</template>

<script>
/* eslint-disable */
import { defineComponent, ref, reactive, toRefs, onMounted, inject, computed } from 'vue'
import feather from 'feather-icons'
import axios from 'axios'
import { BASE_URL } from '@/constant/config.js'
import { CheckSquareIcon } from '@zhuowenli/vue-feather-icons'
import router from '@/router'
import Tabulator from 'tabulator-tables'
import cash from 'cash-dom'
import { helper as $h } from '@/utils/helper'
import store from '@/store'
import moment from 'moment';

export default defineComponent({
  setup() {
    const swal = inject("$swal");
    const tableRef = ref()
    const tabulator = ref()
    const user_details = computed(() => store.state.user.currentUser)
    const pagedata = reactive({
      materials: [],
      products: [],
      users: [],
      transaction_types: [],
      loading: false,
    });
    const formData = reactive({
      s_date: moment().startOf('month').format('YYYY-MM-DD'),
      e_date:moment().format('YYYY-MM-DD'),
      transaction_type: '0',
      action_user: '0',
      product_id: '0',
      material_id: '0',
    })
    const initTabulator = () => {
      tabulator.value = new Tabulator(tableRef.value, {
        ajaxURL: BASE_URL + "api/uac_index",
        ajaxConfig: {
          method: "GET", //set request type to Position
          headers: {
            "Content-type": 'application/json; charset=utf-8', //set specific content type
            "Authorization": "Bearer " + localStorage.getItem("token")
          },
        },
        ajaxParams: {
          "product_id": formData.product_id != '0' ? window.btoa(formData.product_id) : '',
          "material_id": formData.material_id != '0' ? window.btoa(formData.material_id) : '',
          "action_user": formData.action_user != '0' ? window.btoa(formData.action_user) : '',
          "transaction_type": formData.transaction_type != '0' ? window.btoa(formData.transaction_type) : '',
          "s_date": window.btoa(formData.s_date),
          "e_date": window.btoa(formData.e_date)
        },
        ajaxFiltering: true,
        ajaxSorting: true,
        headerSort: false,
        printAsHtml: true,
        printStyled: true,
        pagination: 'remote',
        paginationSize: 20,
        paginationSizeSelector: [20, 30, 40],
        layout: 'fitColumns',
        responsiveLayout: 'collapse',
        placeholder: 'No matching records found',
        columns: [
          {
            formatter: 'responsiveCollapse',
            width: 40,
            minWidth: 30,
            hozAlign: 'center',
            resizable: false,
            headerSort: false
          },

          // For HTML table
          {
            title: 'User',
            minWidth: 100,
            responsive: 0,
            field: 'product_name',
            vertAlign: 'middle',
            hozAlign: 'center',
            print: false,
            download: false,
            formatter(cell) {
              return (cell.getData().creator.first_name ? cell.getData().creator.first_name : '-') + ' ' + cell.getData().creator.last_name
            }

          },
          {
            title: 'Transaction No',
            minWidth: 100,
            responsive: 0,
            field: 'product_name',
            vertAlign: 'middle',
            hozAlign: 'center',
            print: false,
            download: false,
            formatter(cell) {

              if(cell.getData().trans_type?.action_group == 'INWARD') {
                if(cell.getData().trans_type?.action_name == 'IMPORT') {

                  return cell.getData().import?.transaction_no ?? '-'
                } else if(cell.getData().trans_type?.action_name == 'DTA') {

                  return cell.getData().dta?.transaction_no ?? '-'
                }
              } else if(cell.getData().trans_type?.action_group == 'DUTY') {
                return cell.getData().duty?.transaction_no ?? '-'

              } else if(cell.getData().trans_type?.action_group == 'O/W') {
                return cell.getData().ow?.transaction_no ?? '-'
              } else if(cell.getData().trans_type?.action_group == 'RELEASE QTY') {
                return cell.getData().release?.transaction_no ?? '-'
              }
            }

          },
          {
            title: 'Transaction Type',
            minWidth: 100,
            responsive: 0,
            field: 'product_name',
            vertAlign: 'middle',
            hozAlign: 'center',
            print: false,
            download: false,
            formatter(cell) {
              return cell.getData().trans_type.action_name + ' ' + cell.getData().trans_type.action_type
            }

          },
          {
            title: 'Product',
            minWidth: 100,
            responsive: 0,
            field: 'product_name',
            vertAlign: 'middle',
            hozAlign: 'center',
            print: false,
            download: false,
            formatter(cell) {
              return cell.getData().product_item?.product_name ?? '-'
            }

          },
          {
            title: 'Material',
            minWidth: 100,
            responsive: 0,
            field: 'material',
            vertAlign: 'middle',
            hozAlign: 'center',
            print: false,
            download: false,
            formatter(cell) {
              return cell.getData().material_item?.description ?? '-'
            }

          },
          {
            title: 'Created At',
            minWidth: 130,
            responsive: 0,
            field: 'created_time',
            vertAlign: 'middle',
            hozAlign: 'center',
            print: false,
            download: false,
            formatter: "textarea"

          }

        ],
        renderComplete() {
          feather.replace({
            'stroke-width': 1.5
          })
        }
      })
    }
    const get_product_details = () => {
      let promise_url = "api/master/dp_product";
      let promise = axios({
        url: promise_url,
        method: "POST",
        // data: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          pagedata.products = data.items
        })
        .catch(_error => {
          pagedata.products = []

        });
    }

    const get_material_details = () => {
      let promise_url = "api/master/dp_material";
      let promise = axios({
        url: promise_url,
        method: "POST",
        // data: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          pagedata.materials = data.items
        })
        .catch(_error => {
          pagedata.materials = []

        });
    }

    const get_user_details = () => {
      let promise_url = "api/uac_lau";
      let promise = axios({
        url: promise_url,
        method: "POST",
        // data: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          pagedata.users = data.items
        })
        .catch(_error => {
          pagedata.users = []

        });
    }

    const get_transaction_types = () => {
      let promise_url = "api/uac_latt";
      let promise = axios({
        url: promise_url,
        method: "POST",
        // data: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          pagedata.transaction_types = data.items
        })
        .catch(_error => {
          pagedata.transaction_types = []

        });
    }
    const resetFilters = () => {
      formData.s_date = moment().startOf('month').format('YYYY-MM-DD')
      formData.e_date =moment().format('YYYY-MM-DD')
      formData.transaction_type = '0'
      formData.action_user = '0'
      formData.product_id = '0'
      formData.material_id = '0'

      initTabulator()
    }
    onMounted(() => {
      initTabulator();
      get_product_details();
      get_material_details();
      get_user_details();
      get_transaction_types();
    });
    return {
      pagedata,
      formData,
      initTabulator,
      tableRef,
      resetFilters,
      tabulator,
      user_details
    };
  },
  components: { CheckSquareIcon }
})
</script>
