<template>
<div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
        <div>
            <div class="p-0">
                <div class="pos__tabs nav nav-tabs justify-between" role="tablist">
                    <h2 class="text-lg font-medium mr-auto">Release Stock</h2>
                </div>
            </div>
        </div>
        <div class="grid grid-cols-12 gap-x-2 mt-5 p-2">
            <div class="col-span-6 md:col-span-4">
                <label for="regular-form-1" class="form-label">Select Party</label>
                <div id="party_id">

                    <TomSelect v-model="form_data.party_id" :options="{
                        placeholder: 'Select Party'
                      }" class="w-full" name="Party" id="pos-form-2">
                        <option value="0">Select Party</option>
                        <option v-for="item in page_data.party_details" :key="item.id" :value="item.id">{{ item.companey_name}}</option>
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-6 md:col-span-4">
                <label for="regular-form-1" class="form-label">Invoice No</label>
                <div>
                    <input v-model="form_data.invoice_no" type="text" id="invoice_no" class="form-control" placeholder="Invoice No">
                </div>
            </div>
            <div class="col-span-6 md:col-span-4">
                <label for="regular-form-1" class="form-label">Sell Date</label>
                <div>

                    <input type="date" id="sell_date" class="form-control" placeholder="" v-model="form_data.sell_date" />
                </div>
            </div>
            <div class="col-span-6 md:col-span-4">
                <label for="regular-form-1" class="form-label">Total Boxes</label>
                <div>
                    <input v-model="form_data.number_of_boxes" type="number" id="total_modules" class="form-control" placeholder="Total JB">
                </div>
            </div>
            <div class="col-span-6 md:col-span-4">
                <label for="regular-form-1" class="form-label">Customer Received QTY</label>
                <div>
                    <input v-model="form_data.quantity_customer_received" type="number" id="total_modules" class="form-control" placeholder="Total JB">
                </div>
            </div>
            <div class="col-span-6 md:col-span-4">
                <label for="regular-form-1" class="form-label">Dispatch Delivery Date</label>
                <div>
                    <input v-model="form_data.dispatch_delivery_date" type="date" id="total_modules" class="form-control" placeholder="Total JB">
                </div>
            </div>
        </div>
        <div class="grid grid-cols-12 gap-x-2 mt-5 border-t-2 py-5 align-top">
            <div class="col-span-6 mt-5 grid grid-cols-12">
              <div class="col-span-12">
                  <div class="p-0">
                      <div class="pos__tabs nav nav-tabs justify-between" role="tablist">
                          <h2 class="text-lg font-medium mr-auto">Attachments</h2>
                      </div>
                  </div>
              </div>
              <div class="col-span-12 mt-2">
                <div class="flex items-center justify-start">

                  <div class="text-md font-bold">Packing List:</div>
                  <span  class="ml-2">
                    <div v-if="form_data.packing_list_url">
                      <a :href="form_data.packing_list_url" target="_blank" class="text-primary-1 underline">{{ form_data.packing_list }}</a>
                      <a href="javascript:;" class="text-primary-3 underline" @click="form_data.packing_list_url  = ''; form_data.packing_list = ''"><XIcon class="h-4 w-4" /></a>
                    </div>
                    <div v-else>
                      <a href="javascript:;" class="text-primary-1 underline" @click="file_modal('packing_list', 'packing_list_url')">Click here to upload</a>

                    </div>
                  </span>
                </div>
              </div>
              <div class="col-span-12 mt-2">
                <div class="flex items-center justify-start">

                  <div class="text-md font-bold">COA Details:</div>
                  <span  class="ml-2">
                    <div v-if="form_data.coa_url">
                      <a :href="form_data.coa_url" target="_blank" class="text-primary-1 underline">{{ form_data.coa }}</a>
                      <a href="javascript:;" class="text-primary-3 underline" @click="form_data.coa_url  = ''; form_data.coa = ''"><XIcon class="h-4 w-4" /></a>
                    </div>
                    <div v-else>
                      <a href="javascript:;" class="text-primary-1 underline" @click="file_modal('coa', 'coa_url')">Click here to upload</a>

                    </div>
                  </span>
                </div>
              </div>
              <div class="col-span-12 mt-2">
                <div class="flex items-center justify-start">

                  <div class="text-md font-bold">Tracking Details:</div>
                  <span  class="ml-2">
                    <div v-if="form_data.tracking_details_url">
                      <a :href="form_data.tracking_details_url" target="_blank" class="text-primary-1 underline">{{ form_data.tracking_details }}</a>
                      <a href="javascript:;" class="text-primary-3 underline" @click="form_data.tracking_details_url  = ''; form_data.tracking_details = ''"><XIcon class="h-4 w-4" /></a>
                    </div>
                    <div v-else>
                      <a href="javascript:;" class="text-primary-1 underline" @click="file_modal('tracking_details', 'tracking_details_url')">Click here to upload</a>

                    </div>
                  </span>
                </div>
              </div>
              <div class="col-span-12 mt-2">
                <div class="flex items-center justify-start">

                  <div class="text-md font-bold">LR Details:</div>
                  <span  class="ml-2">
                    <div v-if="form_data.lr_url">
                      <a :href="form_data.lr_url" target="_blank" class="text-primary-1 underline">{{ form_data.lr }}</a>
                      <a href="javascript:;" class="text-primary-3 underline" @click="form_data.lr_url  = ''; form_data.lr = ''"><XIcon class="h-4 w-4" /></a>
                    </div>
                    <div v-else>
                      <a href="javascript:;" class="text-primary-1 underline" @click="file_modal('lr', 'lr_url')">Click here to upload</a>

                    </div>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-span-6 mt-5 grid grid-cols-12 align-top">
              <div class="col-span-12">
                  <div class="p-0">
                      <div class="pos__tabs nav nav-tabs justify-between" role="tablist">
                          <h2 class="text-lg font-medium mr-auto">IMAGES</h2>
                      </div>
                  </div>
              </div>
              <div class="col-span-12 mt-2" v-for="(image,index) in form_data.dispatch_images" :key="index">
                <div class="flex items-center justify-start">
                  <span  class="ml-2">
                    <div v-if="image.url">
                      {{ index + 1 }}
                      <a :href="image.url" target="_blank" class="text-primary-1 underline">{{ image.name }}</a>
                      <a href="javascript:;" class="text-primary-3 underline" @click="form_data.dispatch_images.splice(index, 1);"><XIcon class="h-4 w-4" /></a>
                    </div>
                  </span>
                </div>
              </div>
              <div class="col-span-12 mt-2">
                <div class="flex items-center justify-start">
                  <span  class="ml-2">
                    <a href="javascript:;" class="text-primary-1 underline" @click="image_modal" v-if="form_data.dispatch_images.length == 0">+ Add Images</a>
                    <a href="javascript:;" class="text-primary-1 underline" @click="image_modal" v-else>+ Add More Images</a>
                  </span>
                </div>
              </div>
            </div>
        </div>

        <div class="intro-y col-span-12 p-5 px-0 border-t-2">
            <div class="intro-y">
                <h2 class="font-medium text-lg mr-auto">Release Modules Emteries</h2>
                <div class="my-6 shadow">

                    <table class="w-full border mt-3">
                        <thead>
                            <tr>
                                <th>No</th>
                                <th>Product</th>
                                <th>Type</th>
                                <th>OW Stock</th>
                                <th>Current Quantity</th>
                                <th>Release Quantity</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="border-b" v-for="(plan,index) in form_data.pow_details" :key="index">
                                <td>{{ index + 1 }}</td>
                                <td :id="'watt_id_' + index" class="p-0 m-0">
                                    <TomSelect v-model="plan.watt_id" :options="{
            placeholder: 'Select Product'
          }" class="w-full" name="Product" id="pos-form-2" @change="get_pow_details(plan)">
                                        <option value="0">Select Product</option>
                                        <option v-for="item in page_data.watt_details" :key="item.id" :value="item.id">{{ item.watt}}</option>
                                    </TomSelect>
                                </td>
                                <td :id="'product_type_' + index" class="p-0 m-0">
                                    <TomSelect v-model="plan.product_type" :options="{
            placeholder: 'Select JB Type'
          }" class="w-full" name="JB Type" id="pos-form-2" @change="get_pow_details(plan)">
                                        <option value="0">Select Type</option>
                                        <option value="1">Poly</option>
                                        <option value="2">MBB</option>
                                    </TomSelect>
                                </td>
                                <td :id="'pow_id_' + index" class="p-0 m-0">
                                    <TomSelect v-model="plan.pow_id" :options="{
            placeholder: 'Select OW'
          }" class="w-full" name="OW" id="pos-form-2" @change="set_module_items(plan)">
                                        <option value="0">Select OW</option>
                                        <option v-for="sb_itm in plan.pow_items" :key="sb_itm.id" :value="sb_itm.id">{{ sb_itm.request_code}}</option>
                                    </TomSelect>
                                </td>
                                <td class="w-32">
                                    <input v-model="plan.cr_quantity" type="number" :id="'cr_quantity_' + index" class="form-control w-full" placeholder="Quantity" disabled>
                                </td>
                                <td class="w-32">
                                    <input v-model="plan.sold_qty" type="number" :id="'sold_qty_' + index" class="form-control w-full" placeholder="Release Quantity" @change="validate_quantity(plan)">
                                </td>
                                <td>
                                    <button type="button" class="text-theme-6 flex items-center" v-if="index != 0" tabindex="-1" @click="remove_request_field(plan)">
                                        <Trash2Icon class="w-5 h-5 mr-1" tabindex="-1" />
                                    </button>
                                </td>
                            </tr>
                            <tr class="border-b">
                                <td></td>
                                <td class="p-0 m-0" colspan="2">Total</td>
                                <td class="p-0 m-0"></td>
                                <td class="p-0 m-0"></td>
                                <td class="p-0 m-0">{{ form_data.total_modules }}</td>
                            </tr>
                        </tbody>

                    </table>

                </div>
            </div>
        </div>
        <div class="flex items-center justify-between">
            <div>
                <!-- <button type="button" @click="add_new_request_field" class="btn btn-primary ml-5 mt-5 mb-5 mr-auto">Add Product</button> -->
            </div>
            <div>

                <button type="button" data-dismiss="modal" class="btn btn-outline-secondary w-32 mr-1">
                    Cancel
                </button>
                <button type="button" class="btn btn-primary w-32" :disabled="form_data.loading" @click="release_stock">
                    Save Details
                    <LoadingIcon v-if="form_data.loading" icon="oval" color="white" class="w-4 h-4 ml-2" />
                </button>
            </div>
        </div>
    </div>

    <div id="upload-image-modal" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <a data-dismiss="modal" href="javascript:;">
            <XIcon class="w-8 h-8 text-gray-500" />
          </a>
          <div class="modal-body p-10">
            <!-- <div id="vertical-form"> -->
            <div class="flex flex-col justify-between mt-5">
              <!-- BEGIN: Data List -->
              <div class="intro-y overflow-auto">
                <div class="flex items-center">
                  <h2 class="intro-y text-lg font-medium mr-5">Upload Image</h2>
                </div>
              </div>
              <div class="grid grid-cols-12 gap-4">
                <div class="intro-y col-span-12">
                  <!-- BEGIN: Dropzone -->
                  <div class="dropzone" id="stock-dropzone"></div>
                  <!-- END: Dropzone -->
                </div>
              </div>
              <!-- END: Data List -->
            </div>
            <!-- </div> -->
          </div>
        </div>
      </div>
    </div>
    <div id="upload-file-modal" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <a data-dismiss="modal" href="javascript:;">
            <XIcon class="w-8 h-8 text-gray-500" />
          </a>
          <div class="modal-body p-10">
            <!-- <div id="vertical-form"> -->
            <div class="flex flex-col justify-between mt-5">
              <!-- BEGIN: Data List -->
              <div class="intro-y overflow-auto">
                <div class="flex items-center">
                  <h2 class="intro-y text-lg font-medium mr-5">Upload File</h2>
                </div>
              </div>
              <div class="grid grid-cols-12 gap-4">
                <div class="intro-y col-span-12">
                  <!-- BEGIN: Dropzone -->
                  <div class="dropzone" id="stock-file-dropzone"></div>
                  <!-- END: Dropzone -->
                </div>
              </div>
              <!-- END: Data List -->
            </div>
            <!-- </div> -->
          </div>
        </div>
      </div>
    </div>
</div>
</template>

<script>
/* eslint-disable */
import {
    defineComponent,
    reactive, ref,
    onMounted,
    inject,
} from 'vue'
import axios from 'axios'
import {
    BASE_URL
} from '@/constant/config.js'
import Dropzone from 'dropzone'
import cash from 'cash-dom'

export default defineComponent({
    setup() {
        const swal = inject('$swal')
        const stockDropzone = ref(null)
        const stockFileDropzone = ref(null)
        const page_data = reactive({
          base_url: BASE_URL,
            stock_type: 2,
            count_ids: 1,
            watt_details: [],
            party_details: [],
            purchase_item: [],
            request_loading: false,
            purchase_loading: false,
            request_details: {
                request_item: [],
                request_products: [],
                new_quantity: ''
            },
            product_details: {
                product_detail: [],
                purchase_details: [],
                total: [],
                selected_quantity: 0,
                required_quantity: 0,
                limit_touch: false,
            },
            duty_assigned_arr: [],
            message: '',
            ref_no: '',
            deliver_id: '',
            cr_file: '',
            cr_file_url: '',
        })

        const form_data = reactive({
            url: 'api/osc-rs',
            pow_url: 'api/osc-gpd',
            loading: false,
            party_id: '0',
            invoice_no: '',
            sell_date: '',
            total_modules: '',
            dispatch_images: [],
            packing_list: '',
            coa: '',
            tracking_details: '',
            lr: '',
            packing_list_url: '',
            coa_url: '',
            tracking_details_url: '',
            lr_url: '',
            number_of_boxes: '',
            quantity_customer_received: '',
            dispatch_delivery_date: '',
            pow_details: [{
                id: 1,
                watt_id: '0',
                product_type: '0',
                cr_quantity: '',
                sold_qty: '',
                pow_id: '0',
                pow_items: [],
            }],
        })
        // Get Product Details
        const sizeDetails = async () => {

            const params = {};

            let promise = axios({
                url: "api/master/dp_size",
                method: "POST",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    page_data.watt_details = data.items

                })
                .catch(_error => {

                    page_data.watt_details = []

                });
        }
        const get_pow_details = async (item) => {

          if(item.watt_id != '0' && item.product_type != '0') {

            // Get OW Details
            const params = {
              watt_id: window.btoa(item.watt_id),
              product_type: window.btoa(item.product_type)
            };

            let promise = axios({
                url: form_data.pow_url,
                method: "POST",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    item.pow_items = data.items
                    page_data.pow_id = '0'

                })
                .catch(_error => {

                    item.pow_items = []
                    page_data.pow_id = '0'

                });
          } else {
            item.pow_items = []
            page_data.pow_id = '0'
          }

        }

        const validate_quantity = (item) => {

          if(parseFloat(item.cr_quantity ? item.cr_quantity : 0) < parseFloat(item.sold_qty ? item.sold_qty : 0)) {

            item.sold_qty = '';
          }

          let total_module = 0

          form_data.pow_details.forEach(element => {

            total_module += parseFloat(element.sold_qty ? element.sold_qty : 0);

          });

          form_data.total_modules = total_module
        }

        const set_module_items = (item) => {


          item.pow_items.forEach(element => {
            if(item.pow_id == element.id) {
              item.cr_quantity = element.cr_quantity

              validate_quantity(item)
              return;
            }
          });


        }
        // Add Product Field
        const add_new_request_field = async () => {

            page_data.count_ids += 1;
            form_data.value.push({
                id: 1,
                watt_id: '0',
                product_type: '0',
                cr_quantity: '',
                sold_qty: '',
                pow_id: '0',
                pow_items: [],
            });

        }
        // Remove Request From Pannel
        const remove_request_field = async (item) => {

            swal({
                title: "Are you sure?",
                text: "You are about to remove record!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#064e3b",
                cancelButtonColor: "#d33",
                confirmButtonText: "Delete",
                showLoaderOnConfirm: true,
                cancelButtonText: "Cancel",
                allowOutsideClick: () => !$swal.isLoading()
            }).then(result => {
                if (result.isConfirmed) {
                    var i = form_data.value.length;
                    while (i--) {
                        if (form_data.value[i]["id"] == item.id) {
                            form_data.value.splice(i, 1);
                            form_data.value = form_data.value.filter(function () {
                                return true;
                            });
                            console.warn(form_data.value)
                            break
                        }
                    }
                }
            });

        }

        // Sell Stocks
        const release_stock = async () => {
            if (form_data.pow_details.length == 0) {
                swal({
                    title: "Warning!",
                    icon: "warning",
                    text: "Fields are Empty!",
                    type: "warning",
                    confirmButtonColor: "#064e3b",
                })
                return
            }
            // Validate Fileds
            let purchase_detail_length = form_data.pow_details.length

            let is_empty = false

            // Validate Records

            if (form_data.party_id == '0') {
                $('#party_id').addClass('border border-theme-6');
                is_empty = true;
            } else {
                $('#party_id').removeClass('border border-theme-6');
            }

            if (form_data.sell_date == '0') {
                $('#sell_date').addClass('border border-theme-6');
                is_empty = true;
            } else {
                $('#sell_date').removeClass('border border-theme-6');
            }

            if (form_data.total_modules == '' || form_data.total_modules == 0) {
                $('#total_modules').addClass('border border-theme-6');
                is_empty = true;
            } else {
                $('#total_modules').removeClass('border border-theme-6');
            }

            for (let i = 0; i < purchase_detail_length; i++) {

                if (form_data.pow_details[i].watt_id == '0') {
                    $('#watt_id_' + i).addClass('border border-theme-6');
                    is_empty = true;
                } else {
                    $('#watt_id_' + i).removeClass('border border-theme-6');
                }
                if (form_data.pow_details[i].product_type == '0') {
                    $('#product_type_' + i).addClass('border border-theme-6');
                    is_empty = true;
                } else {
                    $('#product_type_' + i).removeClass('border border-theme-6');
                }
                if (form_data.pow_details[i].sold_qty == '') {
                    $('#sold_qty_' + i).addClass('border border-theme-6');
                    is_empty = true;
                } else {
                    $('#sold_qty_' + i).removeClass('border border-theme-6');
                }
                if (form_data.pow_details[i].pow_id == '0') {
                    $('#pow_id_' + i).addClass('border-theme-6');
                    is_empty = true;
                } else {
                    $('#pow_id_' + i).removeClass('border-theme-6');
                }
            }

            if (is_empty == true) {
                swal({
                    title: "Warning!",
                    icon: "warning",
                    text: "Fields Are Empty!",
                    type: "warning",
                    confirmButtonColor: "#064e3b",
                })
                return
            }

            //screenData.return_progress = true;
            swal({
                title: "Are you sure?",
                text: "You want to Send Request to Stock Department!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#064e3b",
                cancelButtonColor: "#d33",
                confirmButtonText: "Submit",
                showLoaderOnConfirm: true,
                cancelButtonText: "Cancel",

                allowOutsideClick: () => !$swal.isLoading()
            }).then(result => {
                if (result.isConfirmed) {

                    form_data.loading = true

                    let promise = axios({
                        url: form_data.url,
                        method: "POST",
                        data: form_data,
                        baseURL: BASE_URL,
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("token")
                        },
                        config: {
                            headers: {
                                Accept: "application/json"
                            }
                        }
                    });
                    return promise
                        .then(result => result.data)
                        .then(data => {

                            if (data.status == "success") {
                                swal.fire({
                                    icon: "success",
                                    title: "Success!",
                                    text: data.message,
                                    showConfirmButton: false,
                                    timer: 2000
                                })
                                form_data.loading = false

                                  form_data.party_id = '0'
                                  form_data.sell_date = ''
                                  form_data.invoice_no = ''
                                  form_data.total_modules = ''
                                  form_data.pow_details = [{
                                    id: 1,
                                    watt_id: '0',
                                    product_type: '0',
                                    cr_quantity: '',
                                    sold_qty: '',
                                    pow_id: '0',
                                    pow_items: [],
                                }]

                            } else {
                                swal({
                                    title: "Warning!",
                                    icon: "warning",
                                    text: data.message,
                                    type: "warning",
                                    confirmButtonColor: "#064e3b",
                                })
                                form_data.loading = false

                            }
                        })
                        .catch(_error => {
                            form_data.loading = false

                        });

                }
            });
        }

        // Get Outward Party Details
        const get_party_details = async () => {

          let promise_url = "api/master/dp_outward_party";
          let params = {}

          let promise = axios({
            url: promise_url,
            method: "POST",
            data: params,
            baseURL: BASE_URL,
            headers: { Authorization: "Bearer " + localStorage.getItem("token") },
            config: { headers: { Accept: "application/json" } }
          });
          return promise
          .then(result => result.data)
          .then(data => {
            page_data.party_details = data.items
          })
          .catch(_error => {
            page_data.party_details = []

          });
        }
        const image_modal = () => {
          cash('#upload-image-modal').modal('show')
        }
        const file_modal = (type, type_url) => {
          page_data.cr_file = type
          page_data.cr_file_url = type_url
          cash('#upload-file-modal').modal('show')
        }
        onMounted(() => {

            sizeDetails()
            get_party_details()

            stockDropzone.value = new Dropzone('#stock-dropzone', {
              url: `${BASE_URL}api/dispatch-images/upload`,
              thumbnailWidth: 150,
              addRemoveLinks: true,
              maxFiles: 15,
              headers: {
                Authorization: 'Bearer ' + window.localStorage.getItem('token')
              },
              init: function() {

                this.on('error', function(file, errorMessage) {
                  this.removeFile(file)
                  swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'No more files please!'
                  })
                })

                this.on('success', function(file, response) {
                  console.log(response.files, 'response')

                  form_data.dispatch_images.push({name: response.file_name, url: response.path})
                  cash('#upload-image-modal').modal('hide')

                  // purchase.packet_details[packetIndex].url = response.path;

                  swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'File Uploaded'
                  })
                  this.removeFile(file)
                })
              }
            })
            stockFileDropzone.value = new Dropzone('#stock-file-dropzone', {
              url: `${BASE_URL}api/dispatch-files/upload`,
              thumbnailWidth: 150,
              addRemoveLinks: true,
              maxFiles: 1,
              headers: {
                Authorization: 'Bearer ' + window.localStorage.getItem('token')
              },
              init: function() {

                this.on('error', function(file, errorMessage) {
                  this.removeFile(file)
                  swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'No more files please!'
                  })
                })

                this.on('success', function(file, response) {
                  console.log(response.file_name, 'response')

                  form_data[page_data.cr_file] = response.file_name
                  form_data[page_data.cr_file_url] = response.path
                  cash('#upload-file-modal').modal('hide')

                  swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'File Uploaded'
                  })
                  this.removeFile(file)
                })
              }
            })
        })

        return {
            page_data,
            form_data,
            get_pow_details,
            add_new_request_field,
            remove_request_field,
            release_stock,
            set_module_items,
            validate_quantity,
            stockDropzone,
            image_modal,
            file_modal,
            stockFileDropzone
        }
    }
})
</script>

<style scoped>
.tableFixHead {
    overflow-y: auto;
    /* make the table scrollable if height is more than 200 px  */
    max-height: 700px;
    /* gives an initial height of 200px to the table */
}

.tableFixHead thead {
    position: sticky;
    /* make the table heads sticky */
    top: 0px;
    /* table head will be placed from the top of the table and sticks to it */
}
</style>
