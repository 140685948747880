/* eslint-disable */
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import globalComponents from './global-components'
import utils from './utils'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import axios from "axios";
import { BASE_URL } from "@/constant/config.js";
import './libs'
import VueKeepScrollPosition from 'vue-keep-scroll-position'
// import moment from 'moment'
import { helper as $h } from '@/utils/helper'

// SASS Theme
import './assets/sass/app.scss'

const app = createApp(App)
  .use(store)
  .use(router)
  .use($h)
  // .use(VueKeepScrollPosition)
  // .use(moment)
  .use(VueSweetalert2)

  global.jQuery = require('jquery');
  var $ = global.jQuery;
  window.$ = $;

  window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
axios.defaults.baseURL = BASE_URL;


const http = axios.create({
    baseURL: BASE_URL,
    headers: { 'Content-Type': 'application/json' }
})

globalComponents(app)
utils(app)

app.mount('#app')
