<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Purchase wise Fail Material</h2>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="grid grid-cols-12 gap-x-2 mt-5">
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <label for="input-quantity" class="form-label">Purchase Code</label>
          <input
            id="input-quantity"
            class="form-control"
            placeholder="Purchase Code"
            autocomplete="off"
            v-model="duty_filters.purchase_code"
            type="text">
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <label for="pos-form-2" class="form-label">Material</label>
          <TomSelect
            v-model="duty_filters.product_id"
              :options="{
                placeholder: 'Select Material'
              }"
              class="w-full"
              name="Material"
              id="pos-form-2"
              @change="materialDetails"
          >
          <option value="0">Select Material</option>
          <option v-for="item in pagedata.product_details" :key="item.id" :value="item.id">{{ item.product_name}}</option>
          </TomSelect>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <label for="pos-form-2" class="form-label">Specification</label>
          <TomSelect
            v-model="duty_filters.material_id"
              :options="{
                placeholder: 'Select Specification'
              }"
              class="w-full"
              name="Specification"
              id="pos-form-2"
          >
          <option value="0">Select Specification</option>
          <option v-for="item in pagedata.material_details" :key="item.id" :value="item.id">{{ item.description}}</option>
          </TomSelect>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <label for="pos-form-2" class="form-label">Material Type</label>
          <TomSelect
            v-model="duty_filters.product_type"
              :options="{
                placeholder: 'Select Material Type'
              }"
              class="w-full"
              name="Material Type"
              id="pos-form-2"
          >
          <option value="0">Select Material Type</option>
          <option value="1">Poly</option>
          <option value="2">MBB</option>
          <option value="3">Both</option>
          </TomSelect>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <label for="pos-form-2" class="form-label">Purchase Type</label>
          <TomSelect
            v-model="duty_filters.product_type"
              :options="{
                placeholder: 'Select Material Type'
              }"
              class="w-full"
              name="Material Type"
              id="pos-form-2"
          >
          <option value="0">Select Purchase Type</option>
          <option value="1">Import</option>
          <option value="2">DTA</option>
          </TomSelect>
        </div>
        <div class="col-span-3 md:col-span-2 xl:col-span-1">
          <div class="mt-5">
            <button
            @click="resetStockFilters"
            class="btn btn-secondary w-full mt-2">
              Reset
            </button>
          </div>
        </div>
        <div class="col-span-3 md:col-span-2 xl:col-span-1">
          <div class="mt-5">
            <button
            @click="initTabulator"
            class="btn btn-primary w-full mt-2">
              Generate
            </button>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden">
        <div
          id="tabulator"
          ref="tableRef"
          class="mt-5 table-report table-report--tabulator"
        ></div>
      </div>
    </div>
    <!-- END: HTML Table Data -->

    <!-- BEGIN: Modal Content -->
    <div
      id="new-failed-material-modal"
      class="modal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <!-- BEGIN: Modal Header -->
          <div class="modal-header">
            <h2 class="font-medium text-base mr-auto">
              Confirm Failed Quantity
            </h2>
          </div>
          <!-- END: Modal Header -->
          <!-- BEGIN: Modal Body -->
          <div class="modal-body grid grid-cols-12 gap-4 gap-y-3">
            <div class="col-span-12">
              <div class="text-3xl mt-5 text-center">Are you sure?</div>
              <div class="text-gray-600 mt-2 text-center">
                Please note that this will automatically <br>
                update Failed Material Quantity, <br>
              </div>
            </div>
            <div class="col-span-12 sm:col-span-6">
              <label for="modal-form-1" class="form-label"
                >Failed Material Quantity</label
              >
              <div class="input-group mt-2">

                <input
                  id="modal-form-1"
                  type="text"
                  v-model="pagedata.failed_qty"
                  class="form-control"
                  placeholder="Failed Quantity"
                  autocomplete="off"
                /><div class="input-group-text">{{ pagedata.uqc_name }}</div>
              </div>
            </div>
            <div class="col-span-12">
              <div class="text-black mt-2"><b>Please confirm the Failed Material Quantity !</b></div>
            </div>
          </div>
          <!-- END: Modal Body -->
          <!-- BEGIN: Modal Footer -->
          <div class="modal-footer text-right">
            <button
              type="button"
              data-dismiss="modal"
              class="btn btn-outline-secondary w-28 mr-1"
            >
              Cancel
            </button>
            <button type="button" class="btn btn-primary" :disabled="pagedata.failed_loading" @click="add_failed_material_quantity">
              Add Fail Quantity <LoadingIcon v-if="pagedata.failed_loading" icon="oval" color="white" class="w-4 h-4 ml-2" />
            </button>
          </div>
          <!-- END: Modal Footer -->
        </div>
      </div>
    </div>
    <!-- END: Modal Content -->
  </div>

</template>

<script>
/* eslint-disable */
import { defineComponent, ref, reactive, toRefs,onMounted, inject, computed } from 'vue'
import xlsx from 'xlsx'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import axios from 'axios'
import VueRouter from 'vue-router'
import { useRouter, useRoute } from 'vue-router'
import { BASE_URL } from '@/constant/config.js'
import cash from 'cash-dom'
import { helper as $h } from '@/utils/helper'
import store from '@/store'

export default defineComponent({
  setup() {
    const swal = inject('$swal')
    const userDetails = computed(() => store.state.user.currentUser)
    const tableRef = ref()
    const tabulator = ref()
    const pagedata = reactive({
      product_details: [],
      material_details: [],
      accept_loading: false,
      failed_loading: false,
      failed_qty: "",
      purchase_id: "",
      uqc_name: ""
    })
    const duty_filters = reactive({
      product_id: "0",
      material_id: "0",
      product_type: "0",
      purchase_type: "0",
      purchase_code: "",
    })
    const print_filters = reactive({
      product_id: "0",
      material_id: "0",
      product_type: "0",
      purchase_type: "0",
      purchase_code: "",
    })

    const initTabulator = () => {

      print_filters.product_id = duty_filters.product_id
      print_filters.material_id = duty_filters.material_id
      print_filters.product_type = duty_filters.product_type
      print_filters.purchase_type = duty_filters.purchase_type
      print_filters.purchase_code = duty_filters.purchase_code

      let params = {
        "product_id": print_filters.product_id == "0" ? "" : window.btoa(print_filters.product_id),
        "material_id": print_filters.material_id == "0" ? "" : window.btoa(print_filters.material_id),
        "product_type": print_filters.product_type == "0" ? "" : window.btoa(print_filters.product_type),
        "purchase_type": print_filters.purchase_type == "0" ? "" : window.btoa(print_filters.purchase_type),
        "purchase_code": print_filters.purchase_code == "" ? "" : window.btoa(print_filters.purchase_code)
      }
      tabulator.value = new Tabulator(tableRef.value, {
        ajaxURL: BASE_URL + 'api/get_purchase_stock_details',
        ajaxConfig:{
            method:"GET", //set request type to Position
            headers: {
                "Content-type": 'application/json; charset=utf-8', //set specific content type
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
        },
        ajaxParams: params,
        pagination: 'remote',
        paginationSize: 20,
        paginationSizeSelector: [20, 30, 40],
        ajaxFiltering: true,
        ajaxSorting: true,
        headerSort: false,
        printAsHtml: true,
        printStyled: true,
        layout: 'fitColumns',
        // responsiveLayout: 'collapse',
        placeholder: 'No matching records found',
        columns: [

          // For HTML table
          {
            title: 'Purchase',
            minWidth: 99,
            responsive: 0,
            field: 'purchase_code',
            vertAlign: 'middle',
            hozAlign:'center',
            print: false,
            download: false,
            formatter: "textarea"
          },
          {
            title: 'Material',
            minWidth: 95,
            responsive: 0,
            field: 'product_name',
            vertAlign: 'middle',
            hozAlign:'center',
            print: false,
            download: false,
            formatter: "textarea"
          },
          {
            title: 'Description',
            minWidth: 115,
            responsive: 0,
            field: 'description',
            vertAlign: 'middle',
            hozAlign:'center',
            print: false,
            download: false,
            formatter: "textarea"
          },
          {
            title: 'Type',
            minWidth: 70,
            responsive: 0,
            field: 'product_type',
            hozAlign:'center',
            vertAlign: 'middle',
            hozAlign:'center',
            print: false,
            download: false,
            formatter(cell) {
              return cell.getData().product_type == 1 ? "Poly" : (cell.getData().product_type == 2 ? "MBB" : "Both")
            }
          },
          {
            title: 'Purchase <br> Qty',
            responsive: 0,
            minWidth: 99,
            field: 'purchase_qty',
            vertAlign: 'middle',
            hozAlign:'center',
            print: false,
            download: false,
            formatter(cell) {
              return $h.myFloat(cell.getData().purchase_qty)+ ' ' + cell.getData().uqc_name
            }
          },
          {
            title: 'Current <br> Qty',
            responsive: 0,
            minWidth: 88,
            field: 'cr_qty',
            vertAlign: 'middle',
            hozAlign:'center',
            print: false,
            download: false,
            formatter(cell) {
              return cell.getData().cr_qty
            }
          },
          {
            title: 'Failed <br> Qty',
            responsive: 0,
            minWidth: 75,
            field: 'failed_qty',
            vertAlign: 'middle',
            hozAlign:'center',
            print: false,
            download: false,
            formatter(cell) {
              return cell.getData().failed_qty ? $h.myFloat(cell.getData().failed_qty) : '0'
            }
          },
          {
            title: 'Duty <br> Paid <br> Qty',
            responsive: 0,
            minWidth: 80,
            field: 'cr_qty_paid',
            vertAlign: 'middle',
            hozAlign:'center',
            print: false,
            download: false,
            formatter(cell) {
              return cell.getData().cr_qty_paid ? $h.myFloat(cell.getData().cr_qty_paid) : 0
            }
          },
          {
            title: 'OOC <br> Pending <br> Qty',
            responsive: 0,
            minWidth: 98,
            field: 'cr_qty_ooc_pending',
            vertAlign: 'middle',
            hozAlign:'center',
            print: false,
            download: false,
            formatter(cell) {
              return cell.getData().cr_qty_ooc_pending ? $h.myFloat(cell.getData().cr_qty_ooc_pending) : '0'
            }
          },
          {
            title: 'Duty <br> Pending <br> Qty',
            responsive: 0,
            minWidth: 100,
            field: 'cr_qty_pending',
            vertAlign: 'middle',
            hozAlign:'center',
            print: false,
            download: false,
            formatter(cell) {
              return cell.getData().cr_qty_pending ? $h.myFloat(cell.getData().cr_qty_pending) : 0
            }
          },
          {
            title: 'Duty <br> Request <br> Qty',
            responsive: 0,
            minWidth: 100,
            field: 'cr_qty_duty_request',
            vertAlign: 'middle',
            hozAlign:'center',
            print: false,
            download: false,
            formatter(cell) {
              return cell.getData().cr_qty_duty_request ? $h.myFloat(cell.getData().cr_qty_duty_request) : 0
            }
          },
          // {
          //   title: 'Status',
          //   // minWidth: ,
          //   minWidth: 100,
          //   field: 'active_status',
          //   hozAlign: 'center',
          //   vertAlign: 'middle',
          //   hozAlign:'center',
          //   print: false,
          //   download: false,
          //   formatter(cell) {
          //     return `<div class="flex items-center lg:justify-center ${
          //       cell.getData().active_status == 1 ? 'text-theme-9' : 'text-theme-6'
          //     }">
          //       <i data-feather="check-square" class="w-4 h-4 mr-2"></i> ${
          //         cell.getData().active_status == 1 ? 'Active' : 'Inactive'
          //       }
          //     </div>`
          //   }
          // },
          {
            title: 'Actions',
            minWidth: 100,
            field: 'actions',
            responsive: 1,
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              if([1,2].includes(userDetails.value.user_type)) {

                const a = cash(`<div class="flex lg:justify-center items-center">
                    <button class="btn btn-primary py-1 px-3">
                      Fail Qty
                    </button>

                  </div>`)
                // const a = cash(`<div class="flex lg:justify-center items-center">
                //     <button class="btn btn-primary py-1">
                //       <i data-feather="check-square" class="w-4 h-4 mr-1"></i> Fail Qty
                //     </button>

                //   </div>`)
                  cash(a).on('click', function() {
                    pagedata.purchase_id = cell.getData().id
                    pagedata.uqc_name = cell.getData().uqc_name
                    pagedata.failed_qty = ''
                    cash('#new-failed-material-modal').modal('show')
                  })

                  return a[0]
              }
            }
          },

        ],
        renderComplete() {
          feather.replace({
            'stroke-width': 1.5
          })
        }
      })

      sessionStorage.setItem('purchase_stock_filters',JSON.stringify(print_filters))
    }

    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener('resize', () => {
        tabulator.value.redraw()
        feather.replace({
          'stroke-width': 1.5
        })
      })
    }

    // Get Material Details
    const productDetails = async () => {

      const params = {
      };

      let promise = axios({
        url: "api/master/dp_product",
        method: "POST",
        data: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
      .then(result => result.data)
      .then(data => {

        pagedata.product_details = data.items

      })
      .catch(_error => {

        pagedata.product_details = []

      });
    }

    // Get Material Details
    const materialDetails = async () => {

      const params = {
        product_id: duty_filters.product_id != "0" ? window.btoa(duty_filters.product_id) : ""
      };

      let promise = axios({
        url: "api/master/get_details_for_tax",
        method: "POST",
        data: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
      .then(result => result.data)
      .then(data => {

        pagedata.material_details = data.items

        let is_match = false
        pagedata.material_details.forEach(element => {
          if(element.id == duty_filters.material_id) {
            is_match = true
          }
        });

        if(is_match == false) {
          duty_filters.material_id = "0"
        }
      })
      .catch(_error => {

        duty_filters.material_id = "0"
        pagedata.material_details = []

      });
    }
    // New Failed Material Qty
    const add_failed_material_quantity = async () => {

      //screenData.return_progress = true;
      swal({
        title: "Are you sure?",
        text: "You want to Enter Failed Material Quantity!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#064e3b",
        cancelButtonColor: "#d33",
        confirmButtonText: "Add Quantity",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        allowOutsideClick: () => !$Swal.isLoading()
      }).then(result => {
          if(result.isConfirmed) {

            if(pagedata.failed_qty == "") {
              swal({
                title: "Warning!",
                icon: "warning",
                text: "Please Enter Material Quantity.",
                type: "warning",
                confirmButtonColor: "#064e3b",
              })
              // swal("Warning!", "Please Enter Failed Number First.", "warning");
            } else if(isNaN(pagedata.failed_qty)) {
              swal({
                title: "Warning!",
                icon: "warning",
                text: "Please Enter Valid Quantity.",
                type: "warning",
                confirmButtonColor: "#064e3b",
              })
            } else {

              pagedata.failed_loading  = true

              const params = {
                id: window.btoa(pagedata.purchase_id),
                failed_qty: pagedata.failed_qty == "" ? "" : window.btoa(pagedata.failed_qty)
              };

              let promise = axios({
                url: "api/add_failed_quantity",
                method: "POST",
                data: params,
                baseURL: BASE_URL,
                headers: { Authorization: "Bearer " + localStorage.getItem("token") },
                config: { headers: { Accept: "application/json" } }
              });
              return promise
              .then(result => result.data)
              .then(data => {

                if(data.error == "NO") {
                  swal.fire({
                    icon: "success",
                    title: "Success!",
                    text: data.message,
                    showConfirmButton: false,
                    timer: 1500
                  })
                  initTabulator()
                  pagedata.failed_loading  = false
                  cash('#new-failed-material-modal').modal('hide')
                } else {
                  swal({
                    title: "Warning!",
                    icon: "warning",
                    text: data.message,
                    type: "warning",
                    confirmButtonColor: "#064e3b",
                  })
                  pagedata.failed_loading  = false
                }

              })
              .catch(_error => {
                pagedata.failed_loading  = false

              });
            }
          }
      });
    }

    // On reset filter
    const resetStockFilters = () => {
      duty_filters.product_id = "0";
      duty_filters.material_id = "0";
      duty_filters.product_type = "0";
      duty_filters.purchase_type = "0";
      duty_filters.purchase_code = "";
    }

    // Export
    const onExportCsv = () => {
      tabulator.value.download('csv', 'data.csv')
    }

    const onExportJson = () => {
      tabulator.value.download('json', 'data.json')
    }

    const onExportXlsx = () => {
      const win = window
      win.XLSX = xlsx
      tabulator.value.download('xlsx', 'data.xlsx', {
        sheetName: 'Products'
      })
    }

    const onExportHtml = () => {
      tabulator.value.download('html', 'data.html', {
        style: true
      })
    }

    // Print
    const onPrint = () => {
      tabulator.value.print()
    }

    onMounted(() => {

      let filter_items = sessionStorage.getItem('purchase_stock_filters') != null ? JSON.parse(sessionStorage.getItem('purchase_stock_filters')) : null

      if(filter_items != null) {
        duty_filters.product_id = filter_items.product_id
        duty_filters.material_id = filter_items.material_id
        duty_filters.product_type = filter_items.product_type
        duty_filters.purchase_type = filter_items.purchase_type
        duty_filters.purchase_code = filter_items.purchase_code

      }
      productDetails()
      materialDetails()
      initTabulator()
      reInitOnResizeWindow()
    })


    return {
      tableRef,pagedata,duty_filters,print_filters,
      onExportCsv,
      onExportJson,
      onExportXlsx,
      onExportHtml,
      onPrint,
      initTabulator,
      materialDetails,
      resetStockFilters,
      add_failed_material_quantity,
      userDetails
    }
  }
})
</script>
