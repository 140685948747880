<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Stock Consumptions</h2>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="grid grid-cols-12 gap-x-2 mt-5">
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <label for="pos-form-2" class="form-label">Material</label>
          <TomSelect
            v-model="duty_filters.product_id"
              :options="{
                placeholder: 'Select Material'
              }"
              class="w-full"
              name="Material"
              id="pos-form-2"
              @change="materialDetails"
          >
          <option value="0">Select Material</option>
          <option v-for="item in pagedata.product_details" :key="item.id" :value="item.id">{{ item.product_name}}</option>
          </TomSelect>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <label for="pos-form-2" class="form-label">Specification</label>
          <TomSelect
            v-model="duty_filters.material_id"
              :options="{
                placeholder: 'Select Specification'
              }"
              class="w-full"
              name="Specification"
              id="pos-form-2"
          >
          <option value="0">Select Specification</option>
          <option v-for="item in pagedata.material_details" :key="item.id" :value="item.id">{{ item.description}}</option>
          </TomSelect>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <label for="pos-form-2" class="form-label">Material Type</label>
          <TomSelect
            v-model="duty_filters.product_type"
              :options="{
                placeholder: 'Select Material Type'
              }"
              class="w-full"
              name="Material Type"
              id="pos-form-2"
          >
          <option value="0">Select Material Type</option>
          <option value="1">Poly</option>
          <option value="2">MBB</option>
          </TomSelect>
        </div>
        <!-- <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <label for="pos-form-2" class="form-label">Purchase Type</label>
          <TomSelect
            v-model="duty_filters.product_type"
              :options="{
                placeholder: 'Select Material Type'
              }"
              class="w-full"
              name="Material Type"
              id="pos-form-2"
          >
          <option value="0">Select Purchase Type</option>
          <option value="1">Import</option>
          <option value="2">DTA</option>
          </TomSelect>
        </div> -->
        <div class="col-span-3 md:col-span-2 xl:col-span-1">
          <div class="mt-5">
            <button
            @click="resetStockFilters"
            class="btn btn-secondary w-full mt-2">
              Reset
            </button>
          </div>
        </div>
        <div class="col-span-3 md:col-span-2 xl:col-span-1">
          <div class="mt-5">
            <button
            @click="initTabulator"
            class="btn btn-primary w-full mt-2">
              Generate
            </button>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden">
        <div
          id="tabulator"
          ref="tableRef"
          class="mt-5 table-report table-report--tabulator"
        ></div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>

</template>

<script>
/* eslint-disable */
import { defineComponent, ref, reactive, toRefs,onMounted, inject, computed } from 'vue'
import xlsx from 'xlsx'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import axios from 'axios'
import VueRouter from 'vue-router'
import { useRouter, useRoute } from 'vue-router'
import { BASE_URL } from '@/constant/config.js'
import cash from 'cash-dom'
import { helper as $h } from '@/utils/helper'
import store from '@/store'

export default defineComponent({
  setup() {
    const swal = inject('$swal')
    const userDetails = computed(() => store.state.user.currentUser)
    const tableRef = ref()
    const tabulator = ref()
    const pagedata = reactive({
      product_details: [],
      material_details: [],
      accept_loading: false,
      failed_loading: false,
      failed_qty: "",
      purchase_id: "",
      uqc_name: ""
    })
    const duty_filters = reactive({
      product_id: "0",
      material_id: "0",
      product_type: "0",
      purchase_type: "0"
    })
    const print_filters = reactive({
      product_id: "0",
      material_id: "0",
      product_type: "0",
      purchase_type: "0"
    })

    const initTabulator = () => {

      print_filters.product_id = duty_filters.product_id
      print_filters.material_id = duty_filters.material_id
      print_filters.product_type = duty_filters.product_type
      print_filters.purchase_type = duty_filters.purchase_type

      let params = {
        "product_id": print_filters.product_id == "0" ? "" : window.btoa(print_filters.product_id),
        "material_id": print_filters.material_id == "0" ? "" : window.btoa(print_filters.material_id),
        "product_type": print_filters.product_type == "0" ? "" : window.btoa(print_filters.product_type),
        "purchase_type": print_filters.purchase_type == "0" ? "" : window.btoa(print_filters.purchase_type)
      }
      tabulator.value = new Tabulator(tableRef.value, {
        ajaxURL: BASE_URL + 'api/report/get_stock_assumption',
        ajaxConfig:{
            method:"GET", //set request type to Position
            headers: {
                "Content-type": 'application/json; charset=utf-8', //set specific content type
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
        },
        ajaxParams: params,
        pagination: 'remote',
        paginationSize: 20,
        paginationSizeSelector: [20, 30, 40],
        ajaxFiltering: true,
        ajaxSorting: true,
        headerSort: false,
        printAsHtml: true,
        printStyled: true,
        layout: 'fitColumns',
        // responsiveLayout: 'collapse',
        placeholder: 'No matching records found',
        columns: [

          // For HTML table
          {
            title: 'Material',
            minWidth: 95,
            responsive: 0,
            field: 'product_name',
            vertAlign: 'middle',
            hozAlign:'left',
            print: false,
            download: false,
            formatter: "textarea"
          },
          {
            title: 'Description',
            minWidth: 115,
            responsive: 0,
            field: 'description',
            vertAlign: 'middle',
            hozAlign:'left',
            print: false,
            download: false,
            formatter: "textarea"
          },
          {
            title: 'Type',
            minWidth: 70,
            responsive: 0,
            field: 'product_type',
            hozAlign:'center',
            vertAlign: 'middle',
            hozAlign:'center',
            print: false,
            download: false,
            formatter(cell) {
              return cell.getData().product_type == 1 ? "Poly" : "MBB"
            }
          },
          {
            title: 'Est. JB Quantity',
            responsive: 0,
            minWidth: 99,
            field: 'est_module_stock',
            vertAlign: 'middle',
            hozAlign:'center',
            print: false,
            download: false,
            formatter(cell) {
              return parseFloat(cell.getData().est_module_stock).toFixed(0)
            }
          },
          {
            title: 'Est. Paid Stock JB',
            responsive: 0,
            minWidth: 99,
            field: 'est_paid_module_stock',
            vertAlign: 'middle',
            hozAlign:'center',
            print: false,
            download: false,
            formatter(cell) {
              return parseFloat(cell.getData().est_paid_module_stock).toFixed(0)
            }
          },
          {
            title: 'Est. Pending Stock JB',
            responsive: 0,
            minWidth: 88,
            field: 'est_pending_module_stock',
            vertAlign: 'middle',
            hozAlign:'center',
            print: false,
            download: false,
            formatter(cell) {
              return parseFloat(cell.getData().est_pending_module_stock).toFixed(0)
            }
          }
        ],
        renderComplete() {
          feather.replace({
            'stroke-width': 1.5
          })
        }
      })

      sessionStorage.setItem('purchase_stock_filters',JSON.stringify(print_filters))
    }

    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener('resize', () => {
        tabulator.value.redraw()
        feather.replace({
          'stroke-width': 1.5
        })
      })
    }

    // Get Material Details
    const productDetails = async () => {

      const params = {
      };

      let promise = axios({
        url: "api/master/dp_product",
        method: "POST",
        data: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
      .then(result => result.data)
      .then(data => {

        pagedata.product_details = data.items

      })
      .catch(_error => {

        pagedata.product_details = []

      });
    }

    // Get Material Details
    const materialDetails = async () => {

      const params = {
        product_id: duty_filters.product_id != "0" ? window.btoa(duty_filters.product_id) : ""
      };

      let promise = axios({
        url: "api/master/get_details_for_tax",
        method: "POST",
        data: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
      .then(result => result.data)
      .then(data => {

        pagedata.material_details = data.items

        let is_match = false
        pagedata.material_details.forEach(element => {
          if(element.id == duty_filters.material_id) {
            is_match = true
          }
        });

        if(is_match == false) {
          duty_filters.material_id = "0"
        }
      })
      .catch(_error => {

        duty_filters.material_id = "0"
        pagedata.material_details = []

      });
    }
    // New Failed Material Qty
    const add_failed_material_quantity = async () => {

      //screenData.return_progress = true;
      swal({
        title: "Are you sure?",
        text: "You want to Enter Failed Material Quantity!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#064e3b",
        cancelButtonColor: "#d33",
        confirmButtonText: "Add Quantity",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        allowOutsideClick: () => !$Swal.isLoading()
      }).then(result => {
          if(result.isConfirmed) {

            if(pagedata.failed_qty == "") {
              swal({
                title: "Warning!",
                icon: "warning",
                text: "Please Enter Material Quantity.",
                type: "warning",
                confirmButtonColor: "#064e3b",
              })
              // swal("Warning!", "Please Enter Failed Number First.", "warning");
            } else if(isNaN(pagedata.failed_qty)) {
              swal({
                title: "Warning!",
                icon: "warning",
                text: "Please Enter Valid Quantity.",
                type: "warning",
                confirmButtonColor: "#064e3b",
              })
            } else {

              pagedata.failed_loading  = true

              const params = {
                id: window.btoa(pagedata.purchase_id),
                failed_qty: pagedata.failed_qty == "" ? "" : window.btoa(pagedata.failed_qty)
              };

              let promise = axios({
                url: "api/add_failed_quantity",
                method: "POST",
                data: params,
                baseURL: BASE_URL,
                headers: { Authorization: "Bearer " + localStorage.getItem("token") },
                config: { headers: { Accept: "application/json" } }
              });
              return promise
              .then(result => result.data)
              .then(data => {

                if(data.error == "NO") {
                  swal.fire({
                    icon: "success",
                    title: "Success!",
                    text: data.message,
                    showConfirmButton: false,
                    timer: 1500
                  })
                  initTabulator()
                  pagedata.failed_loading  = false
                  cash('#new-failed-material-modal').modal('hide')
                } else {
                  swal({
                    title: "Warning!",
                    icon: "warning",
                    text: data.message,
                    type: "warning",
                    confirmButtonColor: "#064e3b",
                  })
                  pagedata.failed_loading  = false
                }

              })
              .catch(_error => {
                pagedata.failed_loading  = false

              });
            }
          }
      });
    }

    // On reset filter
    const resetStockFilters = () => {
      duty_filters.product_id = "0";
      duty_filters.material_id = "0";
      duty_filters.product_type = "0";
      duty_filters.purchase_type = "0";
      duty_filters.purchase_code = "";
    }

    // Export
    const onExportCsv = () => {
      tabulator.value.download('csv', 'data.csv')
    }

    const onExportJson = () => {
      tabulator.value.download('json', 'data.json')
    }

    const onExportXlsx = () => {
      const win = window
      win.XLSX = xlsx
      tabulator.value.download('xlsx', 'data.xlsx', {
        sheetName: 'Products'
      })
    }

    const onExportHtml = () => {
      tabulator.value.download('html', 'data.html', {
        style: true
      })
    }

    // Print
    const onPrint = () => {
      tabulator.value.print()
    }

    onMounted(() => {

      let filter_items = sessionStorage.getItem('purchase_stock_filters') != null ? JSON.parse(sessionStorage.getItem('purchase_stock_filters')) : null

      if(filter_items != null) {
        duty_filters.product_id = filter_items.product_id
        duty_filters.material_id = filter_items.material_id
        duty_filters.product_type = filter_items.product_type
        duty_filters.purchase_type = filter_items.purchase_type
        duty_filters.purchase_code = filter_items.purchase_code

      }
      productDetails()
      materialDetails()
      initTabulator()
      reInitOnResizeWindow()
    })


    return {
      tableRef,pagedata,duty_filters,print_filters,
      onExportCsv,
      onExportJson,
      onExportXlsx,
      onExportHtml,
      onPrint,
      initTabulator,
      materialDetails,
      resetStockFilters,
      add_failed_material_quantity,
      userDetails
    }
  }
})
</script>
