<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">BOM (Bill of Materials)</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <a
          href="javascript:;"
          data-toggle="modal"
          data-target="#new-order-modal"
          @click="onResetFilter"
          class="btn btn-primary shadow-md mr-2"
          >New BOM</a
        >
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <form id="tabulator-html-filter-form" class="xl:flex sm:mr-auto">
          <div class="grid grid-cols-12 gap-4 gap-y-3 w-full">
            <div class="col-span-12">
              <label for="pos-form-2" class="form-label">Final Goods</label>
              <TomSelect
                v-model="pagedata.watt_id"
                :options="{
                  placeholder: 'Final Goods'
                }"
                @change="getConsumptionDetails"
                class="w-60"
                name="uqc"
                id="pos-form-2"
              >
                <option value="0">Select Final Good</option>
                <option
                  v-for="item in pagedata.watts"
                  :key="item.id"
                  :value="item.id"
                  >{{ item.watt }}</option
                >
              </TomSelect>
            </div>
          </div>
        </form>
      </div>
      <div class="overflow-x-auto scrollbar-hidden">
        <div>
          <!-- BEgin: Process Report Start-->
          <div id="responsive-table" class="p-5" v-if="pagedata.loading">
            <div class="preview">
              <div class="overflow-x-auto">
                <div
                  class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center"
                >
                  <LoadingIcon icon="bars" class="w-8 h-8" />
                  <div class="text-center text-xs mt-2">
                    Getting Consumptions
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-else>
            <div class="scrollbar-hidden">
              <div class="intro-y col-span-12 overflow-auto">
                <table
                  class="w-full mt-3 border-2"
                  v-if="pagedata.items.length > 0"
                >
                  <thead>
                    <tr class="border-b bg-gray-300 dark:bg-dark-1">
                      <th class="py-2 whitespace-nowrap">#</th>
                      <th class="py-2 whitespace-nowrap">Final Product</th>
                      <th class="py-2 whitespace-nowrap">Code</th>
                      <th class="py-2 whitespace-nowrap">Material Type</th>
                      <th class="py-2 whitespace-nowrap">Total Materials</th>
                      <th class="py-2 whitespace-nowrap border-r">Status</th>
                      <th class="py-2 whitespace-nowrap border-r">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      class="border-b"
                      v-for="(main_item, index) in pagedata.items"
                    :key="index"
                    >
                      <td class="py-2 border-b text-center border-r">
                        {{ index + 1 }}
                      </td>
                      <td class="py-2 border-b text-center border-r">
                        <a class="w-full p-0 m-0" href="javascript:;" data-toggle="modal" data-target="#view-bom-detail-modal" @click="pagedata.product_detail = main_item">{{ main_item.watt }}</a>
                      </td>
                      <td class="py-2 border-b text-center border-r">
                        <a class="w-full p-0 m-0" href="javascript:;" data-toggle="modal" data-target="#view-bom-detail-modal" @click="pagedata.product_detail = main_item">{{ main_item.code }}</a>
                      </td>
                      <td class="py-2 border-b text-center border-r">
                        {{
                          main_item.product_type == 1
                            ? 'Poly'
                            : main_item.product_type == 2
                            ? 'MBB'
                            : main_item.product_type == 3
                            ? 'ALL'
                            : '-'
                        }}
                      </td>
                      <td class="py-2 border-b text-center border-r">
                        {{ main_item.product_details.length }}
                      </td>
                      <td class="py-2 border-b text-center border-r">
                        <div
                          class="text-center"
                          :class="{
                            'text-theme-9': main_item.active_status == 1,
                            'text-theme-6': main_item.active_status != 1
                          }"
                        >
                          <CheckSquareIcon class="w-4 h-4 mr-2" />
                          {{
                            main_item.active_status == 1 ? 'Active' : 'Inactive'
                          }}
                        </div>
                      </td>
                      <td class="py-2 border-b text-center border-r">
                        <div
                          class="flex lg:justify-center items-center"
                          v-if="main_item.active_status == 1"
                        >
                        <a class="w-full p-0 m-0" href="javascript:;" data-toggle="modal" data-target="#new-order-modal" @click="getDetails(main_item.consumption_id)"><CheckSquareIcon class="w-4 h-4 mr-1" /> Edit</a>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div v-else class="text-center py-2">Records Not Found</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: HTML Table Data -->

    <!-- Add Color modal-->

    <div id="new-order-modal" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h2 class="font-medium text-base mr-auto">New Material</h2>
          </div>
          <div class="modal-body grid grid-cols-12 gap-4 gap-y-3">
            <div class="col-span-6">
              <label for="pos-form-2" class="form-label">Select BOM</label>
              <TomSelect
                v-model="productForm.watt_id"
                @change="setProductCode"
                :options="{
                  placeholder: 'Select BOM'
                }"
                :class="{ 'border-theme-6': validate.watt_id.$error }"
                class="w-full"
                name="uqc"
                id="pos-form-2"
              >
                <option value="">Select Product</option>
                <option
                  v-for="item in pagedata.watts"
                  :key="item.id"
                  :value="item.id"
                  >{{ item.watt }}</option
                >
              </TomSelect>
              <template v-if="validate.watt_id.$error">
                <div
                  v-for="(error, index) in validate.watt_id.$errors"
                  :key="index"
                  class="text-theme-6 mt-2"
                >
                  {{ error.$message }}
                </div>
              </template>
            </div>
            <div class="col-span-6">
              <label for="pos-form-2" class="form-label">Material Type</label>
              <TomSelect
                v-model="productForm.product_type"
                :options="{
                  placeholder: 'Select Material Type'
                }"
                class="w-full"
                name="product_type"
                id="pos-form-2"
                @change="ProductTypeChange"
              >
                <option value="1">Poly</option>
                <option value="2">MBB</option>
              </TomSelect>
            </div>
            <div class="col-span-12">
              <table class="w-full border">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Code</th>
                    <th>Raw Material</th>
                    <th>Quantity</th>
                    <th>UQC</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="border"
                    v-for="(plan, index) in productForm.consumption_details"
                    :key="index"
                  >
                    <td class="text-center">{{ index + 1 }}</td>
                    <td class="text-center"> {{ pagedata.product_code }}</td>
                    <td>
                      <div>
                        <TomSelect
                          v-model="plan.product_id"
                          :options="{
                            placeholder: 'Select Material'
                          }"
                          class="w-full"
                          @change="getProductChange(plan)"
                        >
                          <option value="">Select Material</option>
                          <option
                            :value="item.id"
                            v-for="item in pagedata.products"
                            :key="item.id"
                            >{{ item.product_name }} ({{
                              item.part_no ? item.part_no : '-'
                            }})</option
                          >
                        </TomSelect>
                      </div>
                    </td>
                    <td class="w-40">
                      <input
                        v-model="plan.quantity"
                        type="text"
                        class="form-control"
                        placeholder="Quantity"
                      />
                    </td>
                    <td>
                      <div>
                        <TomSelect
                          v-model="plan.uqc_id"
                          :options="{
                            placeholder: 'Select UQC'
                          }"
                          class="w-full"
                          disabled
                        >
                          <option value="">Select uqc</option>
                          <option
                            :value="item.id"
                            v-for="item in pagedata.uqcs"
                            :key="item.id"
                            >{{ item.uqc_name }}</option
                          >
                        </TomSelect>
                      </div>
                    </td>
                    <td>
                      <button
                        type="button"
                        class="text-theme-6 flex items-center"
                        tabindex="-1"
                        @click="removeField(plan.id)"
                      >
                        <Trash2Icon class="w-5 h-5 mr-1" tabindex="-1" />
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-footer">
            <div class="flex items-center justify-between">
              <div>
                <button
                  type="button"
                  @click="AddFieldPlan"
                  class="btn btn-primary ml-5 mt-5 mb-5 mr-auto"
                >
                  Add Material
                </button>
              </div>
              <div>
                <button
                  type="button"
                  data-dismiss="modal"
                  class="btn btn-outline-secondary w-32 mr-1"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  class="btn btn-primary w-32"
                  :disabled="pagedata.submit"
                  @click="submitForm"
                >
                  Save Details
                  <LoadingIcon
                    v-if="pagedata.submit"
                    icon="oval"
                    color="white"
                    class="w-4 h-4 ml-2"
                  />
                </button>
                <button
                  type="button"
                  v-if="productForm.id != ''"
                  class="btn btn-danger w-32 ml-1"
                  @click="deleteItem"
                >
                  Remove
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="view-bom-detail-modal" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h2 class="font-medium text-base mr-auto">View Final Product (BOM)</h2>
          </div>
          <div class="modal-body grid grid-cols-12 gap-4 gap-y-3">
            <div class="col-span-6">
              <label for="pos-form-2" class="form-label">Product Name:</label><b class="ml-3">{{pagedata.product_detail.watt}}</b>
            </div>
            <div class="col-span-6">
              <label for="pos-form-2" class="form-label">Material Type:</label><b class="ml-3">{{pagedata.product_detail.product_type == 1 ? "Poly" : "MBB"}}</b>
            </div>
            <div class="col-span-12">
              <table class="w-full border">
                <thead>
                  <tr>
                    <th class=" py-2">#</th>
                    <th class=" py-2">Code</th>
                    <th class=" py-2">Raw Material</th>
                    <th class=" py-2">Quantity</th>
                    <th class=" py-2">UQC</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="border"
                    v-for="(plan, index) in pagedata.product_detail.product_details"
                    :key="index"
                  >
                    <td class="text-center py-2">{{ index + 1 }}</td>
                    <td class="text-center py-2">{{ plan.code }}</td>
                    <td class="text-center py-2">{{ plan.product_name }} ({{plan.part_no ? plan.part_no : "-"}})</td>
                    <td class="text-center py-2">{{ plan.quantity }}</td>
                    <td class="text-center py-2">{{ plan.uqc_name }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="success-notification-content" class="toastify-content hidden flex">
      <CheckCircleIcon class="text-theme-9" />
      <div class="ml-4 mr-4">
        <div class="font-medium">Details saved successfully!</div>
        <div class="text-gray-600 mt-1"></div>
      </div>
    </div>
    <!-- END: Success Notification Content -->
    <!-- BEGIN: Failed Notification Content -->
    <div id="failed-notification-content" class="toastify-content hidden flex">
      <XCircleIcon class="text-theme-6" />
      <div class="ml-4 mr-4">
        <div class="font-medium">Something went wrong!</div>
        <div class="text-gray-600 mt-1">
          Please check the fileld form.
        </div>
      </div>
    </div>
    <!-- END: Failed Notification Content -->
    <!-- BEGIN: Failed Notification Content -->
    <div
      id="failed-notification-content-dual"
      class="toastify-content hidden flex"
    >
      <XCircleIcon class="text-theme-6" />
      <div class="ml-4 mr-4">
        <div class="font-medium">Material Fields Are Empty!</div>
        <div class="text-gray-600 mt-1">
          Please check the fileld form.
        </div>
      </div>
    </div>
    <!-- END: Failed Notification Content -->
  </div>
</template>

<script>
/* eslint-disable */
import { defineComponent, ref, reactive, toRefs, onMounted, inject } from 'vue'
import xlsx from 'xlsx'
import feather from 'feather-icons'
import Toastify from 'toastify-js'
import {
  required,
  minLength,
  maxLength,
  email,
  url,
  integer
} from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import axios from 'axios'
import VueRouter from 'vue-router'
import { useRouter, useRoute } from 'vue-router'
import { BASE_URL } from '@/constant/config.js'
import { CheckSquareIcon } from '@zhuowenli/vue-feather-icons'
import { stringify } from 'json5'

export default defineComponent({
  setup() {
    const pagedata = reactive({
      watts: [],
      products: [],
      watt_id: '0',
      items: [],
      uqcs: [],
      loading: false,
      material_details: [[]],
      submit: false,
      product_code: '',
      product_detail: [],
    })
    const swal = inject('$swal')
    const productForm = reactive({
      watt_id: '',
      product_type: '1',
      product_count: 1,
      consumption_details: [
        {
          id: 1,
          product_id: '',
          uqc_id: '',
          material_id: '',
          quantity: ''
        }
      ],
      id: ''
    })
    const rules = {
      watt_id: {
        required
      }
    }
    const validate = useVuelidate(rules, toRefs(productForm))
    const getConsumptionDetails = () => {
      pagedata.loading = true
      // console.log(id);
      const params = {
        watt_id: window.btoa(pagedata.watt_id)
      }
      let promise = axios({
        url: 'api/master/get_consumption_details',
        method: 'get',
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { Accept: 'application/json' } }
      })
      return promise
        .then(result => result.data)
        .then(data => {
          pagedata.items = data.items
          // cash('#new-order-modal').modal('show')
          pagedata.loading = false
        })
        .catch(_error => {
          pagedata.loading = false
        })
    }
    // Filter function
    const onFilter = () => {
      getConsumptionDetails()
    }
    const getDetails = id => {
      console.log(id)
      const params = {
        id: id
      }
      let promise = axios({
        url: 'api/master/get_consumption_details_by_id',
        method: 'POST',
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { Accept: 'application/json' } }
      })
      return promise
        .then(result => result.data)
        .then(data => {
          productForm.consumption_details = []
          productForm.watt_id = String(data.item_details.watt_id)
          productForm.product_type = data.item_details.product_type
          productForm.id = data.item_details.id
          setTimeout(() => {
            let consumption_details = data.item_details.consumption_details
            productForm.consumption_details =
              data.item_details.consumption_details

            let length = consumption_details.length
            productForm.product_count =
              productForm.consumption_details[length - 1].id
            productForm.consumption_details.forEach(element => {
              element.product_id = String(element.product_id)

              // console.warn(element.product_id)
              // getProductConsumptionDetails(element);
            })

            // setTimeout(() => {
            //   productForm.consumption_details.forEach(element => {
            //     getProductConsumptionDetails(element);
            //   });

            // }, 100);
            // cash('#new-order-modal').modal('show')
          }, 100)
        })
        .catch(_error => {})
    }
    const submitForm = () => {
      validate.value.$touch()
      if (validate.value.$invalid) {
        Toastify({
          node: cash('#failed-notification-content')
            .clone()
            .removeClass('hidden')[0],
          duration: 3000,
          newWindow: true,
          close: true,
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast()
      } else {
        let is_filled = true

        let length_consumpt = productForm.consumption_details.length

        for (let i = 0; i < length_consumpt; i++) {
          if (
            productForm.consumption_details[i]['product_id'] &&
            productForm.consumption_details[i]['quantity']
          ) {
          } else {
            Toastify({
              node: cash('#failed-notification-content-dual')
                .clone()
                .removeClass('hidden')[0],
              duration: 3000,
              newWindow: true,
              close: true,
              gravity: 'top',
              position: 'right',
              stopOnFocus: true
            }).showToast()
            return
          }
        }

        pagedata.submit = true

        const params = {
          watt_id: productForm.watt_id,
          product_type: productForm.product_type,
          consumption_details: productForm.consumption_details,
          item_id: window.btoa(productForm.id)
        }
        let promise_url = ''
        if (productForm.id != '' && productForm.id != null) {
          promise_url = 'api/master/update_consumption_details'
        } else {
          promise_url = 'api/master/add_consumption_details'
        }
        let promise = axios({
          url: promise_url,
          method: 'POST',
          data: params,
          baseURL: BASE_URL,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { Accept: 'application/json' } }
        })
        return promise
          .then(result => result.data)
          .then(data => {
            Toastify({
              node: cash('#success-notification-content')
                .clone()
                .removeClass('hidden')[0],
              duration: 3000,
              newWindow: true,
              close: true,
              gravity: 'top',
              position: 'right',
              stopOnFocus: true
            }).showToast()
            cash('#new-order-modal').modal('hide')
            getConsumptionDetails()
            productForm.watt_id = ''
            productForm.product_type = 1
            productForm.product_count = 1
            productForm.consumption_details = [
              {
                id: 1,
                product_id: '',
                material_id: '',
                quantity: '',
                materals: []
              }
            ]
            productForm.id = ''
            validate.value.$reset()
            pagedata.submit = false
          })
          .catch(_error => {
            pagedata.submit = false
            swal('Warning', _error.response.data.error, 'warning')
          })
      }
    }
    const deleteItem = () => {
      //screenData.return_progress = true;
      swal({
        title: 'Are you sure?',
        text: 'You want to Dactive Material!',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#064e3b',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Return',
        showLoaderOnConfirm: true,
        cancelButtonText: 'Cancel',
        allowOutsideClick: () => !$Swal.isLoading()
      }).then(result => {
        if (result.isConfirmed) {
          const params = {
            item_id: window.btoa(productForm.id)
          }
          let promise_url = 'api/master/delete_consumption_details'
          let promise = axios({
            url: promise_url,
            method: 'POST',
            data: params,
            baseURL: BASE_URL,
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token')
            },
            config: { headers: { Accept: 'application/json' } }
          })
          return promise
            .then(result => result.data)
            .then(data => {
              Toastify({
                node: cash('#success-notification-content')
                  .clone()
                  .removeClass('hidden')[0],
                duration: 3000,
                newWindow: true,
                close: true,
                gravity: 'top',
                position: 'right',
                stopOnFocus: true
              }).showToast()
              cash('#new-order-modal').modal('hide')
              getConsumptionDetails()
              productForm.watt_id = ''
              productForm.product_type = 1
              productForm.product_count = 1
              productForm.consumption_details = [
                {
                  id: 1,
                  product_id: '',
                  uqc_id: '',
                  material_id: '',
                  quantity: ''
                }
              ]
              productForm.id = ''
            })
            .catch(_error => {})
        }
      })
    }
    function getProductDetails() {
      let promise_url = 'api/master/dp_product'
      let params = {
        // product_type: window.btoa(productForm.product_type)
      }
      let promise = axios({
        url: promise_url,
        method: 'POST',
        data: params,
        baseURL: BASE_URL,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { Accept: 'application/json' } }
      })
      return promise
        .then(result => result.data)
        .then(data => {
          pagedata.products = data.items
        })
        .catch(_error => {
          pagedata.products = []
        })
    }
    function getUQCDetails() {
      let promise_url = 'api/master/dp_uqc'
      let params = {
        // product_type: window.btoa(productForm.product_type)
      }
      let promise = axios({
        url: promise_url,
        method: 'POST',
        data: params,
        baseURL: BASE_URL,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { Accept: 'application/json' } }
      })
      return promise
        .then(result => result.data)
        .then(data => {
          pagedata.uqcs = data.items
        })
        .catch(_error => {
          pagedata.uqcs = []
        })
    }
    function ProductTypeChange() {
      if (productForm.consumption_details.length > 0) {
        let index = productForm.consumption_details.length
        for (let i = 0; i < index; i++) {
          productForm.consumption_details[i]['product_id'] = ''
          productForm.consumption_details[i]['uqc_id'] = ''
          // productForm.consumption_details[i]["material_id"] = "";
          productForm.consumption_details[i]['materals'] = ''
        }
      }
      getProductDetails()
    }
    function getSizeDetails() {
      let promise_url = 'api/master/dp_size'
      let promise = axios({
        url: promise_url,
        method: 'POST',
        // data: params,
        baseURL: BASE_URL,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { Accept: 'application/json' } }
      })
      return promise
        .then(result => result.data)
        .then(data => {
          pagedata.watts = data.items
        })
        .catch(_error => {
          pagedata.watt_id = []
        })
    }
    // On reset filter
    const onResetFilter = () => {
      productForm.watt_id = ''
      productForm.product_type = 1
      productForm.product_count = 1
      productForm.consumption_details = [
        {
          id: 1,
          product_id: '',
          uqc_id: '',
          material_id: '',
          quantity: ''
        }
      ]
      productForm.id = ''
    }
    function removeField(id) {
      swal({
        title: 'Are you sure?',
        text: 'You are about to delete a item!',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#064e3b',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete',
        showLoaderOnConfirm: true,
        cancelButtonText: 'Cancel',
        allowOutsideClick: () => !$Swal.isLoading()
      }).then(result => {
        if (result.isConfirmed) {
          var i = productForm.consumption_details.length
          while (i--) {
            if (productForm.consumption_details[i]['id'] == id) {
              productForm.consumption_details.splice(i, 1)
            }
          }
        }
      })
    }
    function AddFieldPlan() {
      productForm.product_count++
      productForm.consumption_details.push({
        id: productForm.product_count,
        product_id: '',
        uqc_id: '',
        material_id: '',
        quantity: '',
        materals: []
      })
      pagedata.material_details[pagedata.material_details.length] = []
    }
    function getProductConsumptionDetails(plan) {
      var i = productForm.consumption_details.length
      while (i--) {
        if (productForm.consumption_details[i]['id'] == plan.id) {
          const params = {
            product_id: window.btoa(plan.product_id)
          }
          let promise = axios({
            url: 'api/master/get_details_for_tax',
            method: 'post',
            data: params,
            baseURL: BASE_URL,
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token')
            },
            config: { headers: { Accept: 'application/json' } }
          })
          return promise
            .then(result => result.data)
            .then(data => {
              productForm.consumption_details[i]['materals'] = data.items
              // productForm.consumption_details[i]["material_id"] = String(plan.material_id);

              pagedata.products.forEach(element => {
                if (plan.product_id == element.id) {
                  console.warn(element.uqc_id)
                  plan.uqc_id = stringify(
                    element.different_uqc == 1
                      ? element.uqc_id_2
                      : element.uqc_id
                  )
                }
              })
            })
        }
      }
    }
    function getProductChange(plan) {
      var i = productForm.consumption_details.length
      while (i--) {
        if (productForm.consumption_details[i]['id'] == plan.id) {
          productForm.consumption_details[i]['material_id'] = ''
        }
      }
      pagedata.products.forEach(element => {
        if (plan.product_id == element.id) {
          console.warn(element.uqc_id)
          plan.uqc_id = stringify(
            element.different_uqc == 1 ? element.uqc_id_2 : element.uqc_id
          )
        }
      })
      // getProductConsumptionDetails(plan)
    }

    const setProductCode = () => {
      let length = pagedata.watts.length
      for (let index = 0; index < length; index++) {
        if (pagedata.watts[index].id == productForm.watt_id) {
          pagedata.product_code = pagedata.watts[index].code
          break
        }
      }
    }
    onMounted(() => {
      getConsumptionDetails()
      getUQCDetails()
      getSizeDetails()
      getProductDetails()
    })
    return {
      pagedata,
      productForm,
      validate,
      submitForm,
      onFilter,
      deleteItem,
      onResetFilter,
      getConsumptionDetails,
      removeField,
      getProductDetails,
      ProductTypeChange,
      AddFieldPlan,
      getProductConsumptionDetails,
      getDetails,
      getProductChange,
      setProductCode
    }
  },
  components: { CheckSquareIcon }
})
</script>
