<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Stock Details / Consumption</h2>
    </div>
    <!-- BEGIN: HTML Consumption Details -->
    <div class="intro-y box p-5 mt-5">
      <h2 class="text-lg font-medium mr-auto">Estimated JB Production Details</h2>
      <div class="grid grid-cols-12 gap-x-2 mt-5">
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <label for="pos-form-2" class="form-label">Select Product</label>
          <TomSelect
            v-model="consumption_filters.watt_id"
              :options="{
                placeholder: 'Select Product'
              }"
              class="w-full"
              name="Product"
              id="pos-form-2"
              multiple
          >
          <option value="">Select Product</option>
          <option v-for="item in pagedata.watt_details" :key="item.id" :value="item.id">{{ item.watt}}</option>
          </TomSelect>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <label for="pos-form-2" class="form-label">JB Type</label>
          <TomSelect
            v-model="consumption_filters.pannel_type"
              :options="{
                placeholder: 'Select Pannel Type'
              }"
              class="w-full"
              name="Pannel Type"
              id="pos-form-2"
          >
          <option value="0">Select JB Type</option>
          <option value="1">Poly</option>
          <option value="2">MBB</option>
          </TomSelect>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <label for="input-quantity" class="form-label">Required JB</label>
          <input
            id="input-quantity"
            class="form-control"
            placeholder="Enter Required JB" autocomplete="off"
            v-model="consumption_filters.quantity"
            type="text">
        </div>
        <div class="col-span-3 md:col-span-2 xl:col-span-1">
          <div class="mt-5">
            <button
            @click="resetConsumptionFilters"
            class="btn btn-secondary w-full mt-2">
              Reset
            </button>
          </div>
        </div>
        <div class="col-span-3 md:col-span-2 xl:col-span-1">
          <div class="mt-5">
            <button
            @click="generateConsumption"
            class="btn btn-primary w-full mt-2">
              Generate
            </button>
          </div>
        </div>
      </div>
      <div class="border mt-5 p-0 shadow-xl">
        <div class="overflow-x-auto m-0 p-0">
          <table class="w-full text-center text-xs" v-if="consumption_filters.new_quantity == ''">
            <thead>
              <tr class="bg-theme-14">
                <th class="border-b p-2 py-3 dark:border-dark-5 whitespace-nowrap">#</th>
                <th class="border-b p-2 py-3 dark:border-dark-5 whitespace-nowrap">Product</th>
                <th class="border-b p-2 py-3 dark:border-dark-5 whitespace-nowrap">JB Type</th>
                <th class="border-b p-2 py-3 dark:border-dark-5 whitespace-nowrap">Est Total JB</th>
                <th class="border-b p-2 py-3 dark:border-dark-5">Est Duty Paid JB</th>
                <th class="border-b p-2 py-3 dark:border-dark-5">View Product</th>
              </tr>
            </thead>
            <tbody class="text-center" v-if="pagedata.consumption_items.length > 0">
              <tr class="hover:bg-gray-200" :class="{'bg-gray-100': (index % 2) == 0}" v-for="(item, index) in pagedata.consumption_items" :key="index">
                <td class="border-b py-2">{{ index + 1 }}</td>
                <td class="border-b py-2">{{ item.watt }}</td>
                <td class="border-b py-2">{{ item.product_type == 1 ? 'Poly' : 'MBB' }}</td>
                <td class="border-b py-2">{{ item.quantity_all }}</td>
                <td class="border-b py-2">{{ item.quantity_paid }}</td>
                <th class="border-b py-2 dark:border-dark-5 whitespace-nowrap">
                  <button class="btn btn-primary p-2" @click="generateConsumptionProducts(item)">View</button>
                </th>
              </tr>
            </tbody>
          </table>
          <table class="w-full text-center text-xs" v-else>
            <thead>
              <tr class="bg-theme-14">
                <th class="border border-b-2 p-2 py-3 dark:border-dark-5 whitespace-nowrap">#</th>
                <th class="border border-b-2 p-2 py-3 dark:border-dark-5 whitespace-nowrap">Product</th>
                <th class="border border-b-2 p-2 py-3 dark:border-dark-5 whitespace-nowrap">JB Type</th>
                <th class="border border-b-2 p-2 py-3 dark:border-dark-5 whitespace-nowrap">Est Total JB</th>
                <th class="border border-b-2 p-2 py-3 dark:border-dark-5">Est Duty Paid JB</th>
                <th class="border border-b-2 p-2 py-3 dark:border-dark-5 whitespace-nowrap">Ramaining Quantity</th>
                <th class="border border-b-2 p-2 py-3 dark:border-dark-5 whitespace-nowrap">View Product</th>
              </tr>
            </thead>
            <tbody v-if="pagedata.consumption_items.length > 0">
              <tr class="hover:bg-gray-200" :class="{'bg-gray-100': (index % 2) == 0}" v-for="(item, index) in pagedata.consumption_items" :key="index">
                <td class="border py-2">{{ index + 1 }}</td>
                <td class="border py-2">{{ item.watt }}</td>
                <td class="border py-2">{{ item.product_type == 1 ? 'Poly' : 'MBB' }}</td>
                <td class="border py-2">{{ item.quantity_all }}</td>
                <td class="border py-2">{{ item.quantity_paid }}</td>
                <td class="border py-2" :class="{'text-primary-3' : item.diff_qty < 0,'text-primary-4' : item.diff_qty > 0}" >{{ item.diff_qty > 0 ? '+' : '' }} {{ item.diff_qty }}</td>
                <th class="border py-2 border-b-2 dark:border-dark-5 whitespace-nowrap">
                  <button class="btn btn-primary p-2" @click="generateConsumptionProducts(item)">View</button>
                </th>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="pagedata.consumption_items.length == 0" class="text-md font-medium mr-auto text-center my-8">
          Records Not Found
        </div>
      </div>
    </div>
    <!-- END: HTML Consumption Details -->

    <!-- BEGIN: HTML Product Details -->
    <div class="intro-y box p-5 mt-5">
      <h2 class="text-lg font-medium mr-auto">Material Stock && Duty Details</h2>
      <!-- Filter For Product Details -->
      <div class="grid grid-cols-12 gap-x-2 mt-5">
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <label for="pos-form-2" class="form-label">Select Material</label>
          <TomSelect
            v-model="stock_filters.product_id"
              :options="{
                placeholder: 'Select Material'
              }"
              class="w-full"
              name="Material"
              id="pos-form-2"
          >
          <option value="0">Select Material</option>
          <option v-for="item in pagedata.products" :key="item.id" :value="item.id">{{ item.product_name}}</option>
          </TomSelect>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <label for="pos-form-2" class="form-label">Product Type</label>
          <TomSelect
            v-model="stock_filters.product_type"
              :options="{
                placeholder: 'Select Product Type'
              }"
              class="w-full"
              name="Product Type"
              id="pos-form-2"
          >
          <option value="0">Product Type</option>
          <option value="1">Poly</option>
          <option value="2">MBB</option>
          <option value="3">Both</option>
          </TomSelect>
        </div>
        <div class="col-span-3 md:col-span-2 xl:col-span-1">
          <div class="mt-5">
            <button
            @click="resetStockFilters"
            class="btn btn-secondary w-full mt-2">
              Reset
            </button>
          </div>
        </div>
        <div class="col-span-3 md:col-span-2 xl:col-span-1">
          <div class="mt-5">
            <button
            @click="generateStock"
            class="btn btn-primary w-full mt-2">
              Generate
            </button>
          </div>
        </div>
      </div>
      <div class="border mt-5 p-0 shadow-xl">

        <div class="overflow-x-auto m-0 p-0 tableFixHead">
          <table class="w-full text-center text-xs">
            <thead>
              <tr class="bg-theme-14">
                <th class="p-2"></th>
                <!-- <th class="p-2"></th> -->
                <th class="p-2"></th>
                <th class="p-2"></th>
                <th class="py-2 px-2"></th>
                <th class="border border-t-0 dark:border-dark-5 py-2 px-2" colspan="5">Open Order Quantity</th>
                <th class="border border-t-0 dark:border-dark-5 py-2 px-2" colspan="5">In Order Request</th>
                <th class="border border-t-0 dark:border-dark-5 py-2 px-2" colspan="5">Total Material</th>
                <th class="border border-t-0 dark:border-dark-5 py-2 px-2" colspan="4">Order Remaining ({{ pagedata.total_modules}})</th>
                <th class="py-2 px-2"></th>
              </tr>
              <tr class="bg-theme-14">
                <td class="p-2">#</td>
                <td class="p-2">Material</td>
                <!-- <td class="p-2">Specification</td> -->
                <td class="p-2">Type</td>
                <td class="border-r dark:border-dark-5 p-2">UQC</td>
                <td class="border dark:border-dark-5 p-2" rowspan="2">Total</td>
                <td class="border-r dark:border-dark-5 p-2" colspan="2">Duty Paid</td>
                <td class="border-r dark:border-dark-5 p-2" colspan="2">Duty Pending</td>
                <td class="border dark:border-dark-5 p-2" rowspan="2">Total</td>
                <td class="border-r dark:border-dark-5 p-2" colspan="2">Duty Paid</td>
                <td class="border-r dark:border-dark-5 p-2" colspan="2">Duty Pending</td>
                <td class="border dark:border-dark-5 p-2" rowspan="2">Total</td>
                <td class="border-r dark:border-dark-5 p-2" colspan="2">Duty Paid</td>
                <td class="border-r dark:border-dark-5 p-2" colspan="2">Duty Pending</td>
                <td class="border dark:border-dark-5 p-2" rowspan="2">Reuired QTY</td>
                <td class="border dark:border-dark-5 p-2" rowspan="2">Need To Prepare</td>
                <td class="border dark:border-dark-5 p-2" rowspan="2">Porcessable Quantity</td>
                <td class="border dark:border-dark-5 p-2" rowspan="2">Selected Quantity</td>
                <th class="p-2">Purchases</th>

              </tr>
              <tr class="bg-theme-14">
                <td class="border-b dark:border-dark-5 p-2"></td>
                <!-- <td class="border-b dark:border-dark-5 p-2"></td> -->
                <td class="border-b dark:border-dark-5 p-2"></td>
                <td class="border-b dark:border-dark-5 p-2"></td>
                <td class="border-b dark:border-dark-5 py-2 px-2"></td>
                <!-- <td class="border dark:border-dark-5 py-2 px-1"></td> -->
                <td class="border dark:border-dark-5 py-2 px-1">OOC Done</td>
                <td class="border dark:border-dark-5 py-2 px-1">OOC Pending</td>
                <td class="border dark:border-dark-5 py-2 px-1">Duty Pending</td>
                <td class="border dark:border-dark-5 py-2 px-1">Duty Request</td>
                <!-- <td class="border dark:border-dark-5 py-2 px-1"></td> -->
                <td class="border dark:border-dark-5 py-2 px-1">OOC Done</td>
                <td class="border dark:border-dark-5 py-2 px-1">OOC Pending</td>
                <td class="border dark:border-dark-5 py-2 px-1">Duty Pending</td>
                <td class="border dark:border-dark-5 py-2 px-1">Duty Request</td>
                <!-- <td class="border dark:border-dark-5 py-2 px-1"></td> -->
                <td class="border dark:border-dark-5 py-2 px-1">OOC Done</td>
                <td class="border dark:border-dark-5 py-2 px-1">OOC Pending</td>
                <td class="border dark:border-dark-5 py-2 px-1">Duty Pending</td>
                <td class="border dark:border-dark-5 py-2 px-1">Duty Request</td>
                <td class="border-b dark:border-dark-5 py-2 px-2"></td>
              </tr>
              <tr class="bg-gray-200" v-if="print_filters.product_id != '0'">
                <td class="border-b dark:border-dark-5 p-2"></td>
                <td class="border-b dark:border-dark-5 p-2"></td>
                <!-- <td class="border-b dark:border-dark-5 p-2"></td> -->
                <td class="border-b dark:border-dark-5 p-2"></td>
                <td class="border-b dark:border-dark-5 py-2 px-2"></td>
                <td class="border dark:border-dark-5 py-2 px-1">{{ (pagedata.total.cr_qty ? $h.myFloat(pagedata.total.cr_qty) : '-') }}</td>
                <td class="border dark:border-dark-5 py-2 px-1">{{ (pagedata.total.cr_qty_paid ? $h.myFloat(pagedata.total.cr_qty_paid - (pagedata.total.cr_qty_ooc_pending ? pagedata.total.cr_qty_ooc_pending : 0)) : '-') }}</td>
                <td class="border dark:border-dark-5 py-2 px-1">{{ (pagedata.total.cr_qty_ooc_pending ? $h.myFloat(pagedata.total.cr_qty_ooc_pending) : '-') }}</td>
                <td class="border dark:border-dark-5 py-2 px-1">{{ (pagedata.total.cr_qty_pending ? $h.myFloat(pagedata.total.cr_qty_pending) : '-') }}</td>
                <td class="border dark:border-dark-5 py-2 px-1">{{ (pagedata.total.cr_qty_duty_request ? $h.myFloat(pagedata.total.cr_qty_duty_request) : '-') }}</td>
                <td class="border dark:border-dark-5 py-2 px-1">{{ (pagedata.total.hold_qty ? $h.myFloat(pagedata.total.hold_qty) : '-') }}</td>
                <td class="border dark:border-dark-5 py-2 px-1">{{ (pagedata.total.hold_qty_paid ? $h.myFloat(pagedata.total.hold_qty_paid - (pagedata.total.hold_qty_ooc_pending ? pagedata.total.hold_qty_ooc_pending : 0)) : '-') }}</td>
                <td class="border dark:border-dark-5 py-2 px-1">{{ (pagedata.total.hold_qty_ooc_pending ? $h.myFloat(pagedata.total.hold_qty_ooc_pending) : '-') }}</td>
                <td class="border dark:border-dark-5 py-2 px-1">{{ (pagedata.total.hold_qty_pending ? $h.myFloat(pagedata.total.hold_qty_pending) : '-') }}</td>
                <td class="border dark:border-dark-5 py-2 px-1">{{ (pagedata.total.hold_qty_duty_request ? $h.myFloat(pagedata.total.hold_qty_duty_request) : '-') }}</td>
                <td class="border dark:border-dark-5 py-2 px-1">{{ $h.myFloat((pagedata.total.cr_qty ? $h.myFloat(pagedata.total.cr_qty) : 0) + (pagedata.total.hold_qty ? $h.myFloat(pagedata.total.hold_qty) : 0)) }}</td>
                <td class="border dark:border-dark-5 py-2 px-1">{{ $h.myFloat((pagedata.total.cr_qty_paid ? $h.myFloat(pagedata.total.cr_qty_paid) : 0) + (pagedata.total.hold_qty_paid ? $h.myFloat(pagedata.total.hold_qty_paid) : 0) - (pagedata.total.hold_qty_ooc_pending ? pagedata.total.hold_qty_ooc_pending : 0) - (pagedata.total.cr_qty_ooc_pending ? pagedata.total.cr_qty_ooc_pending : 0)) }}</td>
                <td class="border dark:border-dark-5 py-2 px-1">{{ $h.myFloat((pagedata.total.cr_qty_ooc_pending ? pagedata.total.cr_qty_ooc_pending : 0) + (pagedata.total.hold_qty_ooc_pending ? pagedata.total.hold_qty_ooc_pending : 0)) }}</td>
                <td class="border dark:border-dark-5 py-2 px-1">{{ $h.myFloat((pagedata.total.cr_qty_pending ? pagedata.total.cr_qty_pending : 0) + (pagedata.total.hold_qty_pending ? pagedata.total.hold_qty_pending : 0)) }}</td>
                <td class="border dark:border-dark-5 py-2 px-1">{{ $h.myFloat((pagedata.total.cr_qty_duty_request ? pagedata.total.cr_qty_duty_request : 0) + (pagedata.total.hold_qty_duty_request ? pagedata.total.hold_qty_duty_request : 0)) }}</td>
                <td class="border dark:border-dark-5 py-2 px-1">{{ (pagedata.total.required_quantity ? parseFloat(pagedata.total.required_quantity).toFixed(0) : '-') }}</td>
                <td class="border dark:border-dark-5 py-2 px-1">{{ (pagedata.total.need_to_prepare ? parseFloat(pagedata.total.need_to_prepare).toFixed(0) : '-') }}</td>
                <td class="border dark:border-dark-5 py-2 px-1">{{ (pagedata.total.cr_qty ? parseFloat(pagedata.total.cr_qty).toFixed(0) : '-') }}</td>
                <td class="border dark:border-dark-5 py-2 px-1">{{ (pagedata.total.hold_qty ? parseFloat(pagedata.total.hold_qty).toFixed(0) : '-') }}</td>
                <td class="border-b dark:border-dark-5 py-2 px-2"></td>
              </tr>
            </thead>
            <tbody>
              <tr class="hover:bg-gray-200 p-0" :class="{'bg-gray-100': (index % 2) == 0}" v-for="(item, index) in pagedata.product_items" :key="index">
                <td class="border-b py-2">{{ index + 1 }}</td>
                <td class="border-b py-2">{{ item.product_name }}</td>
                <!-- <td class="border-b py-2">{{ item.description }}</td> -->
                <td class="border-b py-2">{{ item.product_type == 1 ? 'Poly' : (item.product_type == 2 ? 'MBB' : (item.product_type == 3 ? 'Both' : '-')) }}</td>
                <td class="border-b border-r py-2">{{ item.different_uqc == 1 ? item.second_uqc_name : item.first_uqc_name }}</td>
                <td class="border-b border-r py-2">{{ (item.cr_qty ? $h.myFloat(item.cr_qty) : '-') }}</td>
                <td class="border-b py-2">{{ (item.cr_qty_paid ? $h.myFloat(item.cr_qty_paid - (item.cr_qty_ooc_pending ? item.cr_qty_ooc_pending : 0)) : '-') }}</td>
                <td class="border-b border-r py-2">{{ (item.cr_qty_ooc_pending ? $h.myFloat(item.cr_qty_ooc_pending) : '-') }}</td>
                <td class="border-b py-2">{{ (item.cr_qty_pending ? $h.myFloat(item.cr_qty_pending) : '-') }}</td>
                <td class="border-b border-r py-2">{{ (item.cr_qty_duty_request ? $h.myFloat(item.cr_qty_duty_request) : '-') }}</td>
                <td class="border-b border-r py-2">{{ (item.hold_qty ? $h.myFloat(item.hold_qty) : '-') }}</td>
                <td class="border-b py-2">{{ (item.hold_qty_paid ? $h.myFloat(item.hold_qty_paid - (item.hold_qty_ooc_pending ? item.hold_qty_ooc_pending : 0)) : '-') }}</td>
                <td class="border-b border-r py-2">{{ (item.hold_qty_ooc_pending ? $h.myFloat(item.hold_qty_ooc_pending) : '-') }}</td>
                <td class="border-b py-2">{{ (item.hold_qty_pending ? $h.myFloat(item.hold_qty_pending) : '-') }}</td>
                <td class="border-b border-r py-2">{{ (item.hold_qty_duty_request ? $h.myFloat(item.hold_qty_duty_request) : '-') }}</td>
                <td class="border-b border-r py-2">{{ $h.myFloat((item.cr_qty ? $h.myFloat(item.cr_qty) : 0) + (item.hold_qty ? $h.myFloat(item.hold_qty) : 0)) }}</td>
                <td class="border-b py-2">{{ $h.myFloat((item.cr_qty_paid ? $h.myFloat(item.cr_qty_paid) : 0) + (item.hold_qty_paid ? $h.myFloat(item.hold_qty_paid) : 0) - (item.hold_qty_ooc_pending ? item.hold_qty_ooc_pending : 0) - (item.cr_qty_ooc_pending ? item.cr_qty_ooc_pending : 0)) }}</td>
                <td class="border-b border-r py-2">{{ $h.myFloat((item.cr_qty_ooc_pending ? item.cr_qty_ooc_pending : 0) + (item.hold_qty_ooc_pending ? item.hold_qty_ooc_pending : 0)) }}</td>
                <td class="border-b py-2 border-r">{{ $h.myFloat((item.cr_qty_pending ? item.cr_qty_pending : 0) + (item.hold_qty_pending ? item.hold_qty_pending : 0)) }}</td>
                <td class="border-b border-r py-2">{{ $h.myFloat((item.cr_qty_duty_request ? item.cr_qty_duty_request : 0) + (item.hold_qty_duty_request ? item.hold_qty_duty_request : 0)) }}</td>
                <td class="border-b border-r py-2">{{ (item.required_quantity ? parseFloat(item.required_quantity).toFixed(0) : '-') }}</td>
                <td class="border-b border-r py-2">{{ (item.need_to_prepare ? parseFloat(item.need_to_prepare).toFixed(0) : '-') }}</td>
                <td class="border-b border-r py-2">{{ (item.cr_qty ? parseFloat(item.cr_qty).toFixed(0) : '-') }}</td>
                <td class="border-b border-r py-2">{{ (item.hold_qty ? parseFloat(item.hold_qty).toFixed(0) : '-') }}</td>
                <td class="border-b py-2">
                  <button class="btn btn-primary p-2" @click="generatePurchaseStock(item, 1)">View</button></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="pagedata.product_items.length == 0" class="text-md font-medium mr-auto text-center my-8">
                Records Not Found
              </div>
      </div>
    </div>
    <!-- END: HTML Product Details -->

    <!--- BEGIN: Consumption Products Model --->
    <div id="consumption-product-details-modal" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <div class="flex items-center justify-between w-full">
              <h2 class="font-medium text-base mr-auto">JB Consumption Details</h2>
              <div>
                <a data-dismiss="modal" href="javascript:;">
                  <XIcon class="w-8 h-8 text-gray-500" />
                </a>
              </div>
            </div>
          </div>
          <div class="modal-body py-10 px-5">

            <div class="sm:flex justify-content-between items-center">
              <h2 class="text-lg mt-2 mr-auto"><b>Product</b> : {{ pagedata.consumption_product_detail.watt }}</h2>
              <h2 class="text-lg mt-2 mr-auto"><b>Type</b> : {{ pagedata.consumption_product_detail.type == 1 ? 'Poly' : (pagedata.consumption_product_detail.type == 2 ? 'MBB' : (pagedata.consumption_product_detail.type == 3 ? 'Both' : '')) }}</h2>
              <h2 class="text-lg mt-2 mr-auto"><b>Quantity</b> : {{ pagedata.consumption_product_detail.quantity ? pagedata.consumption_product_detail.quantity : '0' }} JB</h2>
            </div>

            <div class="border mt-5 p-0 shadow-xl">

              <div class="overflow-x-auto p-0 m-0">
                <table class="w-full text-center text-xs">
                  <thead>
                    <tr class="bg-theme-14">
                      <th class="border-b py-2 px-2 dark:border-dark-5">#</th>
                      <th class="border-b py-2 px-2 dark:border-dark-5">JB <br> Material</th>
                      <!-- <th class="border-b py-2 px-2 dark:border-dark-5">JB <br> Specification</th> -->
                      <th class="border-b py-2 px-2 dark:border-dark-5">JB <br> Type</th>
                      <th class="border-b py-2 px-2 dark:border-dark-5">JB <br> Wise <br> Consumption</th>
                      <th class="border-b py-2 px-2 dark:border-dark-5">Est <br> JB <br> Quantity</th>
                      <th class="border-b py-2 px-2 dark:border-dark-5">Duty <br> Paid <br> Quantity</th>
                      <th class="border-b py-2 px-2 dark:border-dark-5">Duty <br> Pending <br> Quantity</th>
                      <th class="border-b py-2 px-2 dark:border-dark-5">Ramaining <br> Stock <br> Quantity</th>
                      <th class="border-b py-2 px-2 dark:border-dark-5">Purchase Details</th>
                    </tr>
                  </thead>
                  <tbody class="text-center">
                    <tr class="hover:bg-gray-200" :class="{'bg-gray-100': (index % 2) == 0}" v-for="(item, index) in pagedata.consumption_product_detail.products" :key="index">
                      <td class="border-b py-2 px-2">{{ index + 1 }}</td>
                      <td class="border-b py-2 px-2">{{ item.product_name }}</td>
                      <!-- <td class="border-b py-2 px-2">{{ item.description }}</td> -->
                      <td class="border-b py-2 px-2">{{ item.product_type == 1 ? 'Poly' : (item.product_type == 2 ? 'MBB' : (item.product_type == 3 ? 'Both' : '-')) }}</td>
                      <td class="border-b py-2 px-2">{{ item.quantity ? item.quantity : '-' }}</td>
                      <td class="border-b py-2 px-2">{{ item.consumption_quantity ? $h.myFloat(item.consumption_quantity)+ ' ' + item.first_uqc_name : '-' }}</td>
                      <td class="border-b py-2 px-2">{{ item.consumption_quantity_paid ? item.consumption_quantity_paid : '-' }}</td>
                      <td class="border-b py-2 px-2">{{ item.consumption_quantity_pending ? item.consumption_quantity_pending : '-' }}</td>
                      <td class="border-b py-2 px-2" :class="{'text-primary-3' : item.consumption_quantity_diff < 0,'text-primary-4' : item.consumption_quantity_diff > 0}" >{{ item.consumption_quantity_diff > 0 ? '+' : '' }} {{ parseFloat(item.consumption_quantity_diff).toFixed(0) }}</td>
                      <td class="border-b py-2 px-2">
                        <button class="btn btn-primary p-2" @click="generatePurchaseStock(item, 2)">Purchases</button></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div v-if="pagedata.consumption_product_detail.products.length == 0" class="text-md font-medium mr-auto text-center my-8">
                Records Not Found
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <!--- END: Consumption Products Model --->

    <!--- BEGIN: Product Purchases Model --->
    <div id="purchase-details-modals" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <div class="flex items-center justify-between w-full">
              <h2 class="font-medium text-base mr-auto">Product Purchase Details</h2>
              <div>
                <a data-dismiss="modal" href="javascript:;">
                  <XIcon class="w-8 h-8 text-gray-500" />
                </a>
              </div>
            </div>
          </div>
          <div class="modal-body py-10 px-5">

            <div class="sm:flex justify-content-between items-center">
              <h2 class="text-md mt-2 mr-auto"><b>Material</b> : {{ pagedata.product_purchase_detail.product_name }}</h2>
              <h2 class="text-md mt-2 mr-auto"><b>Specification</b> : {{ pagedata.product_purchase_detail.description }}</h2>
              <h2 class="text-md mt-2 mr-auto"><b>Type</b> : {{ pagedata.product_purchase_detail.type == 1 ? 'Poly' : 'MBB' }}</h2>
              <h2 class="text-md mt-2 mr-auto"><b>UQC</b> : {{ pagedata.product_purchase_detail.uqc_name }}</h2>
            </div>

            <div class="border mt-5 p-0 shadow-xl">

              <div class="overflow-x-auto tableFixHead">
                <table class="w-full text-center text-xs">
                  <thead>
                    <tr class="bg-theme-14">
                      <th class="border-b py-2 px-2 dark:border-dark-5">#</th>
                      <th class="border-b py-2 px-2 dark:border-dark-5">Purchase</th>
                      <th class="border-b py-2 px-2 dark:border-dark-5">Purchase Qty</th>
                      <th class="border-b py-2 px-2 dark:border-dark-5">Cr Qty</th>
                      <th class="border-b py-2 px-2 dark:border-dark-5">Failed Qty</th>
                      <th class="border-b py-2 px-2 dark:border-dark-5">Duty Paid Qty</th>
                      <th class="border-b py-2 px-2 dark:border-dark-5">Duty OOC Pending Qty</th>
                      <th class="border-b py-2 px-2 dark:border-dark-5">Duty Pending Qty</th>
                      <th class="border-b py-2 px-2 dark:border-dark-5">Duty Request Qty</th>
                      <th class="border-b py-2 px-2 dark:border-dark-5">Duty Pay Qty</th>
                      <th class="border-b py-2 px-2 dark:border-dark-5">Duty Amount</th>
                      <!-- <th class="border border-b-2 py-2 px-2 dark:border-dark-5">Hold Qty</th>
                      <th class="border border-b-2 py-2 px-2 dark:border-dark-5">Duty Paid Qty</th>
                      <th class="border border-b-2 py-2 px-2 dark:border-dark-5">Duty OOC Pending Qty</th>
                      <th class="border border-b-2 py-2 px-2 dark:border-dark-5">Duty Pending Qty</th>
                      <th class="border border-b-2 py-2 px-2 dark:border-dark-5">Duty Request Qty</th>
                      <th class="border border-b-2 py-2 px-2 dark:border-dark-5">Delivered Qty</th> -->
                      <!-- <th class="border-b border-b-2 py-2 px-2 dark:border-dark-5">Select Duty Pay</th> -->
                    </tr>
                    <tr class="bg-gray-200" v-if="pagedata.product_purchase_detail.total != []">
                      <td class="border-b py-2 px-2"></td>
                      <td class="border-b py-2 px-2">{{ pagedata.product_purchase_detail.total.purchase_code }}</td>
                      <td class="border-b py-2 px-2">{{ pagedata.product_purchase_detail.total.purchase_qty }}</td>
                      <td class="border-b py-2 px-2">{{ pagedata.product_purchase_detail.total.cr_qty }}</td>
                      <td class="border-b py-2 px-2">{{ pagedata.product_purchase_detail.total.failed_qty }}</td>
                      <td class="border-b py-2 px-2">{{ pagedata.product_purchase_detail.total.cr_qty_paid }}</td>
                      <td class="border-b py-2 px-2">{{ pagedata.product_purchase_detail.total.cr_qty_ooc_pending }}</td>
                      <td class="border-b py-2 px-2">{{ pagedata.product_purchase_detail.total.cr_qty_pending }}</td>
                      <td class="border-b py-2 px-2">{{ pagedata.product_purchase_detail.total.cr_qty_duty_request }}</td>
                      <td class="border-b py-2 px-2">{{ pagedata.product_purchase_detail.total.cr_duty_pay_quantity }}</td>
                      <td class="border-b py-2 px-2"></td>
                    </tr>
                  </thead>
                  <tbody class="text-center">
                    <tr class="hover:bg-gray-200" :class="{'bg-gray-100': (index % 2) == 0 && item.theme == ''}" :id="item.purchase_code" v-for="(item, index) in pagedata.product_purchase_detail.purchases" :key="index">
                      <td class="border-b py-2 px-2" :class="{'bg-theme-14' : item.theme != ''}">
                        <div class="flex items-center">
                          <input type="checkbox" class="p-2 form-check" v-model="item.duty_selected" :disabled="item.select_duty == 0">
                          <span class="ml-2"> {{ index + 1 }}</span>
                        </div>
                      </td>
                      <td class="border-b py-2 px-2" :class="{'bg-theme-14 text-gray-500' : item.theme != ''}">{{ item.purchase_code }}</td>
                      <td class="border-b py-2 px-2" :class="{'bg-theme-14 text-gray-500' : item.theme != ''}">{{ item.purchase_qty }}</td>
                      <td class="border-b py-2 px-2" :class="{'bg-theme-14 text-gray-500' : item.theme != ''}">{{ item.cr_qty ? $h.myFloat(item.cr_qty) : '-' }}</td>
                      <td class="border-b py-2 px-2" :class="{'bg-theme-14 text-gray-500' : item.theme != ''}">{{ item.failed_qty ? $h.myFloat(item.failed_qty) : '-' }}</td>
                      <td class="border-b py-2 px-2" :class="{'bg-theme-14 text-gray-500' : item.theme != ''}">{{ item.cr_qty_paid ? $h.myFloat(item.cr_qty_paid) : '-' }}</td>
                      <td class="border-b py-2 px-2" :class="{'bg-theme-14 text-gray-500' : item.theme != ''}">{{ item.cr_qty_ooc_pending ? $h.myFloat(item.cr_qty_ooc_pending) : '-' }}</td>
                      <td class="border-b py-2 px-2" :class="{'bg-theme-14 text-gray-500' : item.theme != ''}">{{ item.cr_qty_pending ? $h.myFloat(item.cr_qty_pending) : '-' }}</td>
                      <td class="border-b py-2 px-2" :class="{'bg-theme-14 text-gray-500' : item.theme != ''}">{{ item.cr_qty_duty_request ? $h.myFloat(item.cr_qty_duty_request) : '-' }}</td>
                      <td class="border-b py-2 px-2" :class="{'bg-theme-14 text-gray-500' : item.theme != ''}">{{ item.cr_duty_pay_quantity ? $h.myFloat(item.cr_duty_pay_quantity) : '-' }}</td>
                      <td class="border-b py-2 px-2" :class="{'bg-theme-14 text-gray-500' : item.theme != ''}">{{ item.duty_pay_amount ? $h.myFloat(item.duty_pay_amount) : '-' }}</td>
                      <!-- <td class="border py-2 px-2" :class="{'bg-theme-16' : item.theme != ''}">{{ item.hold_qty ? item.hold_qty : '-' }}</td>
                      <td class="border py-2 px-2 {{ item.theme }}">{{ item.hold_qty_paid }}</td>
                      <td class="border py-2 px-2 {{ item.theme }}">{{ item.hold_qty_ooc_pending }}</td>
                      <td class="border py-2 px-2 {{ item.theme }}">{{ item.hold_qty_pending }}</td>
                      <td class="border py-2 px-2 {{ item.theme }}">{{ item.hold_qty_duty_request }}</td>
                      <td class="border py-2 px-2" :class="{'bg-theme-16' : item.theme != ''}">{{ item.delivered_qty ? item.delivered_qty : '' }}</td> -->
                      <!-- <td class="border-b py-2 px-2" :class="{'bg-theme-16' : item.theme != ''}">
                        <input type="checkbox" class="p-2 form-check-switch" v-model="item.duty_selected" :disabled="item.select_duty == 0">
                      </td> -->
                    </tr>
                  </tbody>
                </table>
              </div>
              <div v-if="pagedata.product_purchase_detail.purchases.length == 0" class="text-md font-medium mr-auto text-center my-8">
                Records Not Found
              </div>
            </div>
          </div>
          <!-- BEGIN: Modal Footer -->
          <div class="modal-footer text-right" v-if="userDetails.user_type == 1">
                <button type="button" data-dismiss="modal" class="btn btn-outline-secondary mr-1">
                    Cancel
                </button>
                <button type="button" class="btn btn-primary" :disabled="pagedata.request_loading" @click="submitDutyPayRequest">
                    Send Duty Request <LoadingIcon v-if="pagedata.request_loading" icon="oval" color="white" class="w-4 h-4 ml-2" />
                </button>
            </div>
            <!-- END: Modal Footer -->
        </div>
      </div>
    </div>
    <!--- END: Product Purchases Model --->
    <!--- BEGIN: Product Purchases Model --->
    <div id="purchase-details-modal" class="modal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog-lg">
            <div class="modal-content w-full">
                <div class="modal-header">
                    <div class="flex items-center justify-between w-full">
                        <h2 class="font-medium text-base mr-auto">Product Purchase Details</h2>
                        <div>
                            <a data-dismiss="modal" href="javascript:;">
                                <XIcon class="w-8 h-8 text-gray-500" />
                            </a>
                        </div>
                    </div>
                </div>
                <div class="modal-body py-5 px-5">
                    <div class="grid grid-cols-12 gap-x-2">
                        <div class="col-span-12 md:col-span-3 xl:col-span-3 py-1">
                            <h2 class="text-md mt-2 mr-auto"><b>Material</b> : {{ pagedata.product_purchase_detail.product_name }}</h2>
                        </div>
                        <!-- <div class="col-span-12 md:col-span-6 xl:col-span-4 py-1">
                          <h2 class="text-md mt-2 mr-auto"><b>Specification</b> : {{ pagedata.product_purchase_detail.description }}</h2>
                        </div> -->
                        <div class="col-span-12 md:col-span-2 xl:col-span-2 py-1">
                          <h2 class="text-md mt-2 mr-auto"><b>Type</b> : {{ pagedata.product_purchase_detail.type == 1 ? 'Poly' : 'MBB' }}</h2>
                        </div>
                        <div class="col-span-12 md:col-span-2 xl:col-span-2 py-1">
                          <h2 class="text-md mt-2 mr-auto"><b>UQC</b> : {{ pagedata.product_purchase_detail.uqc_name }}</h2>
                        </div>
                        <div class="col-span-6 md:col-span-3 xl:col-span-3">
                          <label for="pos-form-2" class="form-label">Select Specification</label>
                          <TomSelect
                            v-model="stock_filters.material_id"
                              :options="{
                                placeholder: 'Select Specification'
                              }"
                              class="w-full"
                              name="Specification"
                              id="pos-form-2" @change="generatePurchaseStock(pagedata.temp_arr, 2)"
                          >
                          <option value="0">Select Specification</option>
                          <option v-for="item in pagedata.materials" :key="item.id" :value="item.id">{{ item.description}}</option>
                          </TomSelect>
                        </div>
                        <div class="col-span-6 md:col-span-2 xl:col-span-2">
                          <label for="pos-form-2" class="form-label">Availability</label>
                          <TomSelect
                            v-model="stock_filters.availability"
                              :options="{
                                placeholder: 'Select Availability'
                              }"
                              class="w-full"
                              name="Availability"
                              id="pos-form-2" @change="generatePurchaseStock(pagedata.temp_arr, 2)"
                          >
                          <option value="1">Available</option>
                          <option value="2">ALL Purchases</option>
                          </TomSelect>
                        </div>
                    </div>

                    <div class="border mt-5 p-0 shadow-md">

                        <div class="overflow-x-auto tableFixHead">
                            <table class="w-full text-xs text-center">
                                <thead>
                                    <tr class="bg-theme-14">
                                        <th class="border-b py-2 px-2 dark:border-dark-5">#</th>
                                        <th class="border-b py-2 px-2 dark:border-dark-5">Purchase</th>
                                        <th class="border-b py-2 px-2 dark:border-dark-5">Description</th>
                                        <th class="border-b py-2 px-2 dark:border-dark-5">Bill / Date</th>
                                        <th class="border-b py-2 px-2 dark:border-dark-5">Note</th>
                                        <th class="border-b py-2 px-2 dark:border-dark-5">Est Consumption</th>
                                        <th class="border-b py-2 px-2 dark:border-dark-5">Cr Quantity</th>
                                        <th class="border-b py-2 px-2 dark:border-dark-5">Duty Pay <br> Qty</th>
                                        <th class="border-b py-2 px-2 dark:border-dark-5">New Duty <br> Quantity</th>
                                        <th class="border-b py-2 px-2 dark:border-dark-5">EST Duty <br> Amount</th>
                                        <th class="border-b py-2 px-2 dark:border-dark-5">Purchase <br> Quantity</th>
                                        <th class="border-b py-2 px-2 dark:border-dark-5">Failed Qty</th>
                                        <th class="border-b py-2 px-2 dark:border-dark-5">Cr Duty Paid Qty</th>
                                        <th class="border-b py-2 px-2 dark:border-dark-5">Cr Duty OOC Pending Qty</th>
                                        <th class="border-b py-2 px-2 dark:border-dark-5">Cr Duty Pending Qty</th>
                                        <th class="border-b py-2 px-2 dark:border-dark-5">Cr Duty Request Qty</th>
                                        <th class="border-b py-2 px-2 dark:border-dark-5">Hold Qty</th>
                                        <th class="border-b py-2 px-2 dark:border-dark-5">Hold Duty Paid Qty</th>
                                        <th class="border-b py-2 px-2 dark:border-dark-5">Hold Duty OOC Pending Qty</th>
                                        <th class="border-b py-2 px-2 dark:border-dark-5">Hold Duty Pending Qty</th>
                                        <th class="border-b py-2 px-2 dark:border-dark-5">Hold Duty Request Qty</th>
                                        <th class="border-b py-2 px-2 dark:border-dark-5">Delivered Qty</th>
                                        <th class="border-b py-2 px-2 dark:border-dark-5">Accessible <br> Value</th>
                                        <th class="border-b py-2 px-2 dark:border-dark-5">BCD</th>
                                        <th class="border-b py-2 px-2 dark:border-dark-5">Comp. cess</th>
                                        <th class="border-b py-2 px-2 dark:border-dark-5">KFB</th>
                                        <th class="border-b py-2 px-2 dark:border-dark-5">IGST</th>
                                        <th class="border-b py-2 px-2 dark:border-dark-5">Est Duty <br> Amount</th>
                                        <th class="border-b py-2 px-2 dark:border-dark-5">DutyPaid <br> Amount</th>
                                        <th class="border-b py-2 px-2 dark:border-dark-5">Total Duty <br> Amount</th>
                                        <th class="border-b py-2 px-2 dark:border-dark-5">Clearance</th>
                                        <th class="border-b py-2 px-2 dark:border-dark-5">Company Name</th>
                                        <th class="border-b py-2 px-2 dark:border-dark-5">Warehouse <br> DateTime</th>
                                    </tr>
                                    <tr class="bg-gray-200" v-if="pagedata.product_purchase_detail.total != []">
                                        <td class="border-b py-2 px-2"></td>
                                        <td class="border-b py-2 px-2"></td>
                                        <td class="border-b py-2 px-2"></td>
                                        <td class="border-b py-2 px-2"></td>
                                        <td class="border-b py-2 px-2"></td>
                                        <td class="border-b py-2 px-2">{{ (pagedata.product_purchase_detail.total.est_module_stock ? parseFloat(pagedata.product_purchase_detail.total.est_module_stock).toFixed(0) : '-') }}</td>
                                        <td class="border-b py-2 px-2">{{ (pagedata.product_purchase_detail.total.cr_qty ? $h.myFloat(pagedata.product_purchase_detail.total.cr_qty) : '-') }}</td>
                                        <td class="border-b py-2 px-2">{{ (pagedata.product_purchase_detail.total.cr_duty_pay_quantity ? $h.myFloat(pagedata.product_purchase_detail.total.cr_duty_pay_quantity) : '-') }}</td>
                                        <td class="border-b py-2 px-2">{{ (pagedata.product_purchase_detail.total.duty_quantity ? $h.myFloat(pagedata.product_purchase_detail.total.duty_quantity) : '-') }}</td>
                                        <td class="border-b py-2 px-2">{{ (pagedata.product_purchase_detail.total.est_duty_pay_amount ? $h.myFloat(pagedata.product_purchase_detail.total.est_duty_pay_amount) : '-') }}</td>
                                        <td class="border-b py-2 px-2">{{ (pagedata.product_purchase_detail.total.purchase_qty ? $h.myFloat(pagedata.product_purchase_detail.total.purchase_qty) : '-') }}</td>
                                        <td class="border-b py-2 px-2">{{ (pagedata.product_purchase_detail.total.failed_qty ? $h.myFloat(pagedata.product_purchase_detail.total.failed_qty) : '-') }}</td>
                                        <td class="border-b py-2 px-2">{{ (pagedata.product_purchase_detail.total.cr_qty_paid ? $h.myFloat(pagedata.product_purchase_detail.total.cr_qty_paid) : '-') }}</td>
                                        <td class="border-b py-2 px-2">{{ (pagedata.product_purchase_detail.total.cr_qty_ooc_pending ? $h.myFloat(pagedata.product_purchase_detail.total.cr_qty_ooc_pending) : '-') }}</td>
                                        <td class="border-b py-2 px-2">{{ (pagedata.product_purchase_detail.total.cr_qty_pending ? $h.myFloat(pagedata.product_purchase_detail.total.cr_qty_pending) : '-') }}</td>
                                        <td class="border-b py-2 px-2">{{ (pagedata.product_purchase_detail.total.cr_qty_duty_request ? $h.myFloat(pagedata.product_purchase_detail.total.cr_qty_duty_request) : '-') }}</td>
                                        <td class="border-b py-2 px-2">{{ (pagedata.product_purchase_detail.total.hold_qty ? $h.myFloat(pagedata.product_purchase_detail.total.hold_qty) : '-') }}</td>
                                        <td class="border-b py-2 px-2">{{ (pagedata.product_purchase_detail.total.hold_qty_paid ? $h.myFloat(pagedata.product_purchase_detail.total.hold_qty_paid) : '-') }}</td>
                                        <td class="border-b py-2 px-2">{{ (pagedata.product_purchase_detail.total.hold_qty_ooc_pending ? $h.myFloat(pagedata.product_purchase_detail.total.hold_qty_ooc_pending) : '-') }}</td>
                                        <td class="border-b py-2 px-2">{{ (pagedata.product_purchase_detail.total.hold_qty_pending ? $h.myFloat(pagedata.product_purchase_detail.total.hold_qty_pending) : '-') }}</td>
                                        <td class="border-b py-2 px-2">{{ (pagedata.product_purchase_detail.total.hold_qty_duty_request ? $h.myFloat(pagedata.product_purchase_detail.total.hold_qty_duty_request) : '-') }}</td>
                                        <td class="border-b py-2 px-2">{{ (pagedata.product_purchase_detail.total.delivered_qty ? $h.myFloat(pagedata.product_purchase_detail.total.delivered_qty) : '-') }}</td>
                                        <td class="border-b py-2 px-2"></td>
                                        <td class="border-b py-2 px-2"></td>
                                        <td class="border-b py-2 px-2"></td>
                                        <td class="border-b py-2 px-2"></td>
                                        <td class="border-b py-2 px-2"></td>
                                        <td class="border-b py-2 px-2">{{ (pagedata.product_purchase_detail.total.duty_pay_amount ? $h.myFloat(pagedata.product_purchase_detail.total.duty_pay_amount) : '-') }}</td>
                                        <td class="border-b py-2 px-2"></td>
                                        <td class="border-b py-2 px-2"></td>
                                        <td class="border-b py-2 px-2"></td>
                                        <td class="border-b py-2 px-2"></td>
                                        <td class="border-b py-2 px-2"></td>
                                    </tr>
                                </thead>
                                <!-- <tbody class="text-center">
                                    <tr class="hover:bg-gray-200" :class="{'bg-gray-100': (index % 2) == 0 && item.theme == ''}" v-for="(item, index) in page_data.product_details.purchase_details" :key="index">
                                        <td class="border-b py-2 px-2">
                                            <div class="flex items-center">
                                                <input type="checkbox" class="p-2 form-check" v-model="item.selected" @change="update_quantity(item)" :disabled="page_data.product_details.limit_touch == true && item.selected == false">
                                                <span class="ml-2"> {{ index + 1 }}</span>
                                            </div>
                                        </td>
                                        <td class="border-b py-2 px-2">{{ item.purchase_code }}</td>
                                        <td class="border-b py-2 px-2 whitespace-nowrap">{{ item.bill_no ? item.bill_no : '-' }} <br> {{ item.bill_date ? item.bill_date : '' }}</td>
                                        <td class="border-b py-2 px-2">{{ item.description ? item.description : '-' }}</td>
                                        <td class="border-b py-2 px-2"><input type="number" name="required_quantity" id="required_quantity" autocomplete="off" @change="validate_quantity(item)" :disabled="item.selected == false" class="form-control w-20 text-center" v-model="item.cr_new_qty" placeholder="Req Qty"></td>
                                        <td class="border-b py-2 px-2">{{ item.cr_qty ? $h.myFloat(item.cr_qty) : '-' }}</td>
                                        <td class="border-b py-2 px-2">{{ item.purchase_qty ? $h.myFloat(item.purchase_qty) : '-' }}</td>
                                        <td class="border-b py-2 px-2">{{ item.uqc_name ? $h.myFloat(item.uqc_name) : '-' }}</td>
                                        <td class="border-b py-2 px-2">{{ item.cr_qty_paid ? $h.myFloat(item.cr_qty_paid) : '-' }}</td>
                                        <td class="border-b py-2 px-2">{{ item.cr_qty_ooc_pending ? $h.myFloat(item.cr_qty_ooc_pending) : '-' }}</td>
                                        <td class="border-b py-2 px-2">{{ item.cr_qty_pending ? $h.myFloat(item.cr_qty_pending) : '-' }}</td>
                                        <td class="border-b py-2 px-2">{{ item.cr_qty_duty_request ? $h.myFloat(item.cr_qty_duty_request) : '-' }}</td>
                                        <td class="border-b py-2 px-2">{{ item.accessable_value ? $h.myFloat(item.accessable_value) : '-' }}</td>
                                        <td class="border-b py-2 px-2">{{ item.bcd_value ? $h.myFloat(item.bcd_value) : '-' }}</td>
                                        <td class="border-b py-2 px-2">{{ item.igst_value ? $h.myFloat(item.igst_value) : '-' }}</td>
                                        <td class="border-b py-2 px-2">{{ item.comp_cess_value ? $h.myFloat(item.comp_cess_value) : '-' }}</td>
                                        <td class="border-b py-2 px-2">{{ (item.per_unit_qty > 0 && item.cr_new_qty > 0) ? $h.myFloat(item.per_unit_qty * item.cr_new_qty) : 0 }}</td>
                                        <td class="border-b py-2 px-2">{{ item.duty_paid_amount ? item.duty_paid_amount : '-' }}</td>
                                        <td class="border-b py-2 px-2">{{ (item.duty_pay_amount > 0) ? $h.myFloat(item.duty_pay_amount) : 0 }}</td>
                                        <td class="border-b py-2 px-2">{{ item.purchase_clearance ? item.purchase_clearance : '-' }}</td>
                                        <td class="border-b py-2 px-2">{{ item.company_name ? item.company_name : '-' }}</td>
                                        <td class="border-b py-2 px-2">{{ item.warehouse_receipt_time ? item.warehouse_receipt_time : '-' }}</td>
                                    </tr>
                                </tbody> -->
                                <tbody class="text-center">
                                  <tr class="hover:bg-gray-200" :class="{'bg-gray-100': (index % 2) == 0 && item.theme == ''}" :id="item.purchase_code" v-for="(item, index) in pagedata.product_purchase_detail.purchases" :key="index">
                                      <td class="border-b py-2 px-2" :class="{'bg-theme-14' : item.theme != ''}">
                                        <div class="flex items-center">
                                          <input type="checkbox" class="p-2 form-check" v-model="item.duty_selected" :disabled="item.select_duty == 0" @change="calculateQuantity">
                                          <span class="ml-2" :class="{'text-red' : (item.cr_qty_pending > 0 || item.cr_qty_duty_request > 0) && item.purchase_type == 1 ,'text-green' : ((item.cr_qty_pending == 0 || item.cr_qty_pending == null) && (item.cr_qty_duty_request == 0 || item.cr_qty_duty_request == null) && item.purchase_type == 1)}"> {{ index + 1 }}</span>
                                        </div>
                                      </td>
                                      <td class="border-b py-2 px-2" :class="{'text-red' : (item.cr_qty_pending > 0 || item.cr_qty_duty_request > 0) && item.purchase_type == 1 ,'text-green' : ((item.cr_qty_pending == 0 || item.cr_qty_pending == null) && (item.cr_qty_duty_request == 0 || item.cr_qty_duty_request == null) && item.purchase_type == 1)}">{{ item.purchase_code }}</td>
                                      <td class="border-b py-2 px-2">{{ item.description }}</td>
                                      <td class="border-b py-2 px-2 whitespace-nowrap">{{ item.bill_no ? item.bill_no : '-' }} <br> {{ item.bill_date ? item.bill_date : '' }}</td>
                                      <td class="border-b py-2 px-2">{{ item.purchase_type == 1 ? (item.import_note ? item.import_note : '-') : (item.dta_note ? item.dta_note : '-') }}</td>
                                      <td class="border-b py-2 px-2">{{ item.cr_qty ? $h.myFloat(item.cr_qty) : '-' }}</td>
                                      <td class="border-b py-2 px-2">{{ item.est_module_stock ? $h.myFloat(item.est_module_stock) : '-' }}</td>
                                      <td class="border-b py-2 px-2">{{ item.cr_duty_pay_quantity ? $h.myFloat(item.cr_duty_pay_quantity) : '0' }}</td>
                                      <td class="border-b py-2 px-2"><input type="text" class="form-control w-20 p-1" @keypress="$h.preventInvalidInput" v-model="item.duty_quantity" :disabled="!item.cr_duty_pay_quantity" @input="validateQuantity(item)"></td>
                                      <td class="border-b py-2 px-2">{{ item.est_duty_pay_amount ? $h.myFloat(item.est_duty_pay_amount) : '0' }}</td>
                                      <td class="border-b py-2 px-2">{{ item.purchase_qty }}</td>
                                      <td class="border-b py-2 px-2">{{ item.failed_qty ? $h.myFloat(item.failed_qty) : '0' }}</td>
                                      <td class="border-b py-2 px-2">{{ item.cr_qty_paid ? $h.myFloat(item.cr_qty_paid) : '0' }}</td>
                                      <td class="border-b py-2 px-2">{{ item.cr_qty_ooc_pending ? $h.myFloat(item.cr_qty_ooc_pending) : '0' }}</td>
                                      <td class="border-b py-2 px-2">{{ item.cr_qty_pending ? $h.myFloat(item.cr_qty_pending) : '0' }}</td>
                                      <td class="border-b py-2 px-2">{{ item.cr_qty_duty_request ? $h.myFloat(item.cr_qty_duty_request) : '0' }}</td>
                                      <td class="border-b py-2 px-2">{{ item.hold_qty ? $h.myFloat(item.hold_qty) : '0' }}</td>
                                      <td class="border-b py-2 px-2">{{ item.hold_qty_paid ? $h.myFloat(item.hold_qty_paid) : '0' }}</td>
                                      <td class="border-b py-2 px-2">{{ item.hold_qty_ooc_pending ? $h.myFloat(item.hold_qty_ooc_pending) : '0' }}</td>
                                      <td class="border-b py-2 px-2">{{ item.hold_qty_pending ? $h.myFloat(item.hold_qty_pending) : '0' }}</td>
                                      <td class="border-b py-2 px-2">{{ item.hold_qty_duty_request ? $h.myFloat(item.hold_qty_duty_request) : '0' }}</td>
                                      <td class="border-b py-2 px-2">{{ item.delivered_qty ? $h.myFloat(item.delivered_qty) : '0' }}</td>
                                      <td class="border-b py-2 px-2">{{ item.accessable_value ? $h.myFloat(item.accessable_value) : '0' }}</td>
                                      <td class="border-b py-2 px-2">{{ item.bcd_value ? $h.myFloat(item.bcd_value) : '0' }}</td>
                                      <td class="border-b py-2 px-2">{{ item.comp_cess_value ? $h.myFloat(item.comp_cess_value) : '0' }}</td>
                                      <td class="border-b py-2 px-2">{{ item.kfb_value ? $h.myFloat(item.kfb_value) : '0' }}</td>
                                      <td class="border-b py-2 px-2">{{ item.igst_value ? $h.myFloat(item.igst_value) : '0' }}</td>
                                      <td class="border-b py-2 px-2">{{ item.duty_pay_amount ? item.duty_pay_amount : '0' }}</td>
                                      <td class="border-b py-2 px-2">{{ item.duty_paid_amount ? item.duty_paid_amount : '0' }}</td>
                                      <td class="border-b py-2 px-2">{{ (item.duty_amount > 0) ? $h.myFloat(item.duty_amount) : 0 }}</td>
                                      <td class="border-b py-2 px-2">{{ item.purchase_clearance ? item.purchase_clearance : '0' }}</td>
                                      <td class="border-b py-2 px-2">{{ item.company_name ? item.company_name : '-' }}</td>
                                      <td class="border-b py-2 px-2">{{ item.warehouse_receipt_time ? item.warehouse_receipt_time : '-' }}</td>
                                    </tr>
                                  </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="modal-footer text-right" v-if="userDetails.user_type == 1">
                <button type="button" data-dismiss="modal" class="btn btn-outline-secondary mr-1">
                    Cancel
                </button>
                <button type="button" class="btn btn-primary" :disabled="pagedata.request_loading" @click="submitDutyPayRequest">
                    Send Duty Request <LoadingIcon v-if="pagedata.request_loading" icon="oval" color="white" class="w-4 h-4 ml-2" />
                </button>
            </div>
            </div>
        </div>
    </div>
    <!--- END: Product Purchases Model --->
  </div>

</template>

<script>
/* eslint-disable */
import { defineComponent, ref, reactive, toRefs,onMounted, inject, computed } from 'vue'
import feather from 'feather-icons'
import axios from 'axios'
import { BASE_URL } from '@/constant/config.js'
import { CheckSquareIcon } from '@zhuowenli/vue-feather-icons'
import router from '@/router'
import Tabulator from 'tabulator-tables'
import cash from 'cash-dom'
import { helper as $h  } from '@/utils/helper'
import store from '@/store'

export default defineComponent({
  setup() {
    const swal = inject("$swal");
    const userDetails = computed(() => store.state.user.currentUser)
    const pagedata = reactive({
      watt_details: [],
      materials: [],
      products: [],
      consumption_items: [],
      product_items: [],
      loading: false,
      consumption_product_detail: {
        watt: '',
        type: '',
        quantity: '',
        stock_type: '',
        products: []
      },
      product_purchase_detail: {
        product_name: '',
        description: '',
        type: '',
        uqc_name: '',
        purchases: [],
        total: []
      },
      temp_arr: [],
      request_loading: false,
      request_details: []
    });

    // Consumption Filters
    const consumption_filters = reactive({
      watt_id: [],
      pannel_type: '0',
      stock_type: '0',
      quantity: '',
      new_quantity: ''
    })

    // Stock Filters
    const stock_filters = reactive({
      product_id: '0',
      material_id: '0',
      availability: '1',
      product_type: '0'
    })
    // Stock Filters
    const print_filters = reactive({
      product_id: '0',
      material_id: '0',
      availability: '1',
      product_type: '0'
    })

    // Generate Consumption Report Details
    const generateConsumption = async () => {

      consumption_filters.new_quantity = consumption_filters.quantity

      const params = {

      "watt_id": consumption_filters.watt_id.length > 0 ? consumption_filters.watt_id : "",
      "pannel_type": consumption_filters.pannel_type != "0" ? window.btoa(consumption_filters.pannel_type) : "",
      "stock_type": consumption_filters.stock_type != "0" ? window.btoa(consumption_filters.stock_type) : "",
      "quantity": consumption_filters.quantity != "" ? window.btoa(consumption_filters.quantity) : "",

      };

      let promise = axios({
        url: "api/stock_consumption_details",
        method: "POST",
        data: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          pagedata.consumption_items = data.items

        })
        .catch(_error => {

          pagedata.consumption_items = []

        });

    }

    // Generate Consumption Report Details
    const generateConsumptionProducts = async (item) => {

      pagedata.consumption_product_detail.watt = item.watt
      pagedata.consumption_product_detail.type = item.product_type
      pagedata.consumption_product_detail.quantity = consumption_filters.new_quantity == "" ? item.quantity_all : consumption_filters.new_quantity

      const params = {
        "consumption_id": window.btoa(item.id),
        "quantity": window.btoa(pagedata.consumption_product_detail.quantity),
        "quantity_paid": window.btoa(item.quantity_paid),
      };

      cash('#consumption-product-details-modal').modal('show')
      let promise = axios({
        url: "api/stock_consumption_product_details",
        method: "POST",
        data: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          pagedata.consumption_product_detail.products = data.items

        })
        .catch(_error => {

          pagedata.consumption_product_detail.watt = ''
          pagedata.consumption_product_detail.type = ''
          pagedata.consumption_product_detail.quantity = ''
          pagedata.consumption_product_detail.products = []

        });

    }


    // Generate Stock Report Details
    const generateStock = async () => {

      const params = {

        "product_id": stock_filters.product_id != "0" ? window.btoa(stock_filters.product_id) : "",
        // "material_id": stock_filters.material_id != "0" ? window.btoa(stock_filters.material_id) : "",
        "product_type": stock_filters.product_type != "0" ? window.btoa(stock_filters.product_type) : "",

        };

      let promise = axios({
        url: "api/stock_product_details",
        method: "POST",
        data: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          print_filters.product_id = stock_filters.product_id
          print_filters.material_id = stock_filters.material_id
          print_filters.product_type = stock_filters.product_type

          pagedata.product_items = data.items
          pagedata.total_modules = data.total_modules
          pagedata.total = data.total ? data.total : []

        })
        .catch(_error => {

          pagedata.product_items = []
          pagedata.total_modules = ""
          pagedata.total = []

          print_filters.product_id = stock_filters.product_id
          print_filters.material_id = stock_filters.material_id
          print_filters.product_type = stock_filters.product_type

        });

    }

    // Generate Stock Purchase Report Details
    const generatePurchaseStock = async (item, type) => {

      pagedata.temp_arr = item

      const params = {

        "product_id": window.btoa(item.product_id),
        "material_id": stock_filters.material_id != "0" ? window.btoa(stock_filters.material_id) : "",
        "product_type": window.btoa(item.product_type),
        "availability": window.btoa(stock_filters.availability),

      };

      pagedata.product_purchase_detail.product_name = item.product_name
      pagedata.product_purchase_detail.description = item.description
      pagedata.product_purchase_detail.type = item.product_type
      pagedata.product_purchase_detail.uqc_name = item.first_uqc_name

      if(type == 1) {

        stock_filters.material_id = "0"
        stock_filters.availability = "1"
      }

      cash('#purchase-details-modal').modal('show')
      let promise = axios({
        url: "api/get_stock_purchase_details_new",
        method: "POST",
        data: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          pagedata.product_purchase_detail.purchases = data.items
          pagedata.product_purchase_detail.total = data.total

          if(pagedata.product_purchase_detail.purchases.length > 0) {

            // Loop For Adding Data
            pagedata.product_purchase_detail.purchases.forEach(element => {

              if(element.duty_selected == true) {

                element.duty_pay_quantity = $h.myFloat(element.cr_qty_pending - (element.cr_qty_duty_request ? element.cr_qty_duty_request : 0))
              }
            });

            setTimeout(() => {
              pagedata.product_purchase_detail.purchases.forEach(element => {
                if(element.cr_qty_pending > 0) {
                  document.querySelector('#' + element.purchase_code).scrollIntoView({behavior: 'smooth'});
                  return;
                }
              });
              if(type == 1) {

                  materialDetails(pagedata.temp_arr.product_id)
                }


            }, 200);
          }

        })
        .catch(_error => {

          pagedata.product_purchase_detail.product_name = ''
          pagedata.product_purchase_detail.description = ''
          pagedata.product_purchase_detail.type = ''
          pagedata.product_purchase_detail.uqc_name = ''
          pagedata.product_purchase_detail.purchases = []
          pagedata.product_purchase_detail.total = []

        });

    }

    // Get Product Details
    const productDetails = async () => {

      const params = {
      };

      let promise = axios({
        url: "api/master/dp_product",
        method: "POST",
        data: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          pagedata.products = data.items

        })
        .catch(_error => {

          pagedata.products = []

        });
    }

    // Get Material Details
    const materialDetails = async (product_id) => {

      const params = {
        product_id: product_id != "0" ? window.btoa(product_id) : ""
      };

      let promise = axios({
        url: "api/master/get_details_for_tax",
        method: "POST",
        data: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          pagedata.materials = data.items

        })
        .catch(_error => {

          pagedata.materials = []

        });
    }

    // Get Product Details
    const wattDetails = async () => {

      const params = {
      };

      let promise = axios({
        url: "api/master/dp_size",
        method: "POST",
        data: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          pagedata.watt_details = data.items

        })
        .catch(_error => {

          pagedata.watt_details = []

        });
    }

    // Reset Consumption Details Filter
    const resetConsumptionFilters = () => {

      consumption_filters.watt_id = []
      consumption_filters.pannel_type = '0'
      consumption_filters.stock_type = '0'
      consumption_filters.quantity = ''

      generateConsumption()

    }

    // Validate Quantity
    const validateQuantity = (item) =>{

let quantity = 0;
      let duty = 0;
      if($h.myFloat(item.duty_quantity ? item.duty_quantity : 0) > $h.myFloat(item.cr_duty_pay_quantity ? item.cr_duty_pay_quantity : 0)) {

        item.duty_quantity = 0
        swal('Warning', 'Invalid Quantity', 'warning');
      }

      pagedata.product_purchase_detail.purchases.forEach(element => {

        if(element.duty_selected) {
          element.est_duty_pay_amount = $h.myFloat((element.duty_quantity) * element.duty_amount / element.purchase_qty)
          quantity += parseFloat(element.duty_quantity)
          duty += element.est_duty_pay_amount
        }

      });

      pagedata.product_purchase_detail.total['duty_quantity'] = $h.myFloat(quantity);
      pagedata.product_purchase_detail.total['est_duty_pay_amount'] = $h.myFloat(duty);
    }
    const calculateQuantity = () =>{


      let quantity = 0;
      let duty = 0;
      pagedata.product_purchase_detail.purchases.forEach(element => {

        if(element.duty_selected) {
          element.est_duty_pay_amount = $h.myFloat((element.duty_quantity) * element.duty_amount / element.purchase_qty)
          quantity += parseFloat(element.duty_quantity)
          duty += element.est_duty_pay_amount
        } else {
          element.est_duty_pay_amount = 0

        }

      });

      pagedata.product_purchase_detail.total['duty_quantity'] = $h.myFloat(quantity);
      pagedata.product_purchase_detail.total['est_duty_pay_amount'] = $h.myFloat(duty);
    }

    // Submit Duty Pay Request
    const submitDutyPayRequest = async () => {

      //screenData.return_progress = true;
      swal({
        title: "Are you sure?",
        text: "You want to Send Request to Stock Department!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#064e3b",
        cancelButtonColor: "#d33",
        confirmButtonText: "Submit",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        allowOutsideClick: () => !$Swal.isLoading()
      }).then(result => {
          if(result.isConfirmed){

            pagedata.request_loading = true

            pagedata.request_details = []

            // Loop For Adding Data
            pagedata.product_purchase_detail.purchases.forEach(element => {

              if(element.duty_selected == true) {

                if(!element.duty_quantity) {
                  swal('Warning', 'Quantity Fields Empty.', 'warning');
                  return;
                }

                let new_item = {
                  id: element.id,
                  duty_amount: $h.myFloat(element.est_duty_pay_amount),
                  duty_pay_quantity: $h.myFloat(element.duty_quantity ? element.duty_quantity : 0)
                }
                pagedata.request_details.push(new_item)
              }
            });

            const params = {
              "purchases": pagedata.request_details
            };

            let promise = axios({
              url: "api/set_direct_duty_pay_request",
              method: "POST",
              data: params,
              baseURL: BASE_URL,
              headers: { Authorization: "Bearer " + localStorage.getItem("token") },
              config: { headers: { Accept: "application/json" } }
            });
            return promise
              .then(result => result.data)
              .then(data => {

                if(data.error == "NO") {
                  swal.fire({
                    icon: "success",
                    title: "Success!",
                    text: data.message,
                    showConfirmButton: false,
                    timer: 2000
                  })
                  pagedata.request_loading = false
                  pagedata.request_details = [],
                  pagedata.product_purchase_detail.product_name = ''
                  pagedata.product_purchase_detail.description = ''
                  pagedata.product_purchase_detail.type = ''
                  pagedata.product_purchase_detail.uqc_name = ''
                  pagedata.product_purchase_detail.purchases = []
                  pagedata.product_purchase_detail.total = []

                  cash('#purchase-details-modal').modal('hide')
                } else {
                  swal({
                    title: "Warning!",
                    icon: "warning",
                    text: data.message,
                    type: "warning",
                    confirmButtonColor: "#064e3b",
                  })
                  pagedata.request_loading = false

                }
              })
              .catch(_error => {
                pagedata.request_loading = false

              });

          }
      });


    }

    // Reset Stock Details Filter
    const resetStockFilters = () => {

      stock_filters.product_id = '0'
      stock_filters.material_id = '0'
      stock_filters.product_type = '0'
      print_filters.product_id = '0'
      print_filters.availability = '1'
      print_filters.product_type = '0'

      generateStock()

    }

    // Mount Functions
    onMounted(() => {
      productDetails();
      wattDetails();
      generateConsumption();
      generateStock();
    });
    return {
        pagedata,consumption_filters,stock_filters,
        resetConsumptionFilters,resetStockFilters,
        generateConsumption,generateStock,materialDetails,
        generateConsumptionProducts,generatePurchaseStock,
        submitDutyPayRequest,
        userDetails,print_filters,validateQuantity,calculateQuantity
    };
  },
  components: { CheckSquareIcon }
})
</script>
<style scoped>
.tableFixHead {
        overflow-y: auto; /* make the table scrollable if height is more than 200 px  */
        max-height: 650px; /* gives an initial height of 200px to the table */
      }
      .tableFixHead thead {
        position: sticky; /* make the table heads sticky */
        top: 0px; /* table head will be placed from the top of the table and sticks to it */
      }
      </style>
