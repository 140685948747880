<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">OW Report</h2>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="grid grid-cols-12 gap-x-2 mt-5">
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <label for="input-quantity" class="form-label py-1">Purchase Code</label>
          <input
            id="input-quantity"
            class="form-control"
            placeholder="Purchase Code"
            v-model="duty_filters.purchase_code"
            type="text">
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <label for="pos-form-2" class="form-label py-1">Material</label>
          <TomSelect
            v-model="duty_filters.product_id"
              :options="{
                placeholder: 'Select Material'
              }"
              class="w-full"
              name="Material"
              id="pos-form-2"
              @change="materialDetails"
          >
          <option value="0">Select Material</option>
          <option v-for="item in pagedata.product_details" :key="item.id" :value="item.id">{{ item.product_name}}</option>
          </TomSelect>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <label for="pos-form-2" class="form-label py-1">Specification</label>
          <TomSelect
            v-model="duty_filters.material_id"
              :options="{
                placeholder: 'Select Specification'
              }"
              class="w-full"
              name="Specification"
              id="pos-form-2"
          >
          <option value="0">Select Specification</option>
          <option v-for="item in pagedata.material_details" :key="item.id" :value="item.id">{{ item.description}}</option>
          </TomSelect>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <label for="pos-form-2" class="form-label py-1">Material Type</label>
          <TomSelect
            v-model="duty_filters.product_type"
              :options="{
                placeholder: 'Select Material Type'
              }"
              class="w-full"
              name="Material Type"
              id="pos-form-2"
          >
          <option value="0">Select Material Type</option>
          <option value="1">Poly</option>
          <option value="2">MBB</option>
          </TomSelect>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <label for="pos-form-2" class="form-label py-1">Purchase Type</label>
          <TomSelect
            v-model="duty_filters.product_type"
              :options="{
                placeholder: 'Select Material Type'
              }"
              class="w-full"
              name="Material Type"
              id="pos-form-2"
          >
          <option value="0">Select Purchase Type</option>
          <option value="1">Import</option>
          <option value="2">DTA</option>
          </TomSelect>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">

          <label for="update-profile-form-6" class="form-label py-1"
            >Select Start Date</label
          >
          <input
            type= "date"
            id="update-profile-form-13"

            class="form-control"
            placeholder=""
            v-model="duty_filters.s_date"
          />
          </div>
          <div class="col-span-6 md:col-span-3 xl:col-span-2">

          <label for="update-profile-form-6" class="form-label py-1"
            >Select End Date</label
          >
          <input
            type= "date"
            id="update-profile-form-13"

            class="form-control"
            placeholder=""
            v-model="duty_filters.e_date"
          />
        </div>
        <div class="col-span-3 md:col-span-2 xl:col-span-1">
          <div class="mt-7">
            <button
            @click="resetStockFilters"
            class="btn btn-secondary w-full mt-2">
              Reset
            </button>
          </div>
        </div>
        <div class="col-span-3 md:col-span-2 xl:col-span-1">
          <div class="mt-7">
            <button
            @click="get_report_details"
            class="btn btn-primary w-full mt-2">
              Generate
            </button>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden">
        <div>
          <!-- BEgin: Process Report Start-->
          <div id="responsive-table" class="p-5" v-if="pagedata.loading">
            <div class="preview">
              <div class="overflow-x-auto">
                <div
                  class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center"
                >
                  <LoadingIcon icon="bars" class="w-8 h-8" />
                  <div class="text-center text-xs mt-2">Getting Consumptions</div>
              </div>
              </div>
            </div>
          </div>
          <div v-else>

            <div class="border mt-5 p-0 shadow-xl">
              <div class="overflow-x-auto m-0 p-0">
                <table class="text-xs text-center">
                  <thead>
                    <tr class="bg-theme-14">
                      <th class="border-b dark:border-dark-5 whitespace-nowrap"></th>
                      <th class="border-b dark:border-dark-5 whitespace-nowrap"></th>
                      <th class="border-b dark:border-dark-5 whitespace-nowrap"></th>
                      <th class="border-b dark:border-dark-5 whitespace-nowrap"></th>
                      <th class="border-b dark:border-dark-5 whitespace-nowrap"></th>
                      <th class="border-b dark:border-dark-5"></th>
                      <th class="border-b dark:border-dark-5"></th>
                      <th class="border dark:border-dark-5 py-2 px-2" colspan="5">Available Material</th>
                      <th class="border dark:border-dark-5 py-2 px-2" colspan="5">In Order Request</th>
                      <th class="border dark:border-dark-5 py-2 px-2" colspan="2">Delivered Material</th>
                    </tr>
                    <tr class="bg-theme-14">
                      <th class="px-2 border dark:border-dark-5 whitespace-nowrap">NO.</th>
                      <th class="px-2 border dark:border-dark-5 whitespace-nowrap">Purchase Code</th>
                      <th class="px-2 border dark:border-dark-5 whitespace-nowrap">Material</th>
                      <th class="px-2 border dark:border-dark-5 whitespace-nowrap">Description</th>
                      <th class="px-2 border dark:border-dark-5 whitespace-nowrap">Type</th>
                      <th class="px-2 border dark:border-dark-5">Purchase <br> Qty</th>
                      <th class="px-2 border dark:border-dark-5">Failed <br> Qty</th>
                      <th class="px-2 border dark:border-dark-5 py-2 px-2">Total</th>
                      <th class="px-2 border dark:border-dark-5 py-2 px-2" colspan="2">Duty Paid</th>
                      <th class="px-2 border dark:border-dark-5 py-2 px-2" colspan="2">Duty Pending</th>
                      <th class="px-2 border dark:border-dark-5 py-2 px-2">Total</th>
                      <th class="px-2 border dark:border-dark-5 py-2 px-2" colspan="2">Duty Paid</th>
                      <th class="px-2 border dark:border-dark-5 py-2 px-2" colspan="2">Duty Pending</th>
                      <th class="px-2 border dark:border-dark-5 py-2 px-2"></th>
                      <th class="px-2 border dark:border-dark-5 py-2 px-2"></th>
                    </tr>
                    <tr class="bg-theme-14">
                      <th class="border dark:border-dark-5 whitespace-nowrap"></th>
                      <th class="border dark:border-dark-5 whitespace-nowrap"></th>
                      <th class="border dark:border-dark-5 whitespace-nowrap"></th>
                      <th class="border dark:border-dark-5 whitespace-nowrap"></th>
                      <th class="border dark:border-dark-5 whitespace-nowrap"></th>
                      <th class="border dark:border-dark-5"></th>
                      <th class="border dark:border-dark-5"></th>
                      <th class="border dark:border-dark-5 py-2 px-2"></th>
                      <th class="border dark:border-dark-5 py-2 px-2">OOC Done</th>
                      <th class="border dark:border-dark-5 py-2 px-2">OOC Pending</th>
                      <th class="border dark:border-dark-5 py-2 px-2">Duty Pending</th>
                      <th class="border dark:border-dark-5 py-2 px-2">Duty Requested</th>
                      <th class="border dark:border-dark-5 py-2 px-2"></th>
                      <th class="border dark:border-dark-5 py-2 px-2">OOC Done</th>
                      <th class="border dark:border-dark-5 py-2 px-2">OOC Pending</th>
                      <th class="border dark:border-dark-5 py-2 px-2">Duty Pending</th>
                      <th class="border dark:border-dark-5 py-2 px-2">Duty Requested</th>
                      <th class="border dark:border-dark-5 py-2 px-2">Delivered</th>
                      <th class="border dark:border-dark-5 py-2 px-2">Delivered Request Detail</th>
                    </tr>
                  </thead>
                  <tbody class="text-center" v-if="pagedata.data_items.length > 0">
                    <tr class="hover:bg-gray-200" :class="{'bg-gray-100': (index % 2) == 0}" v-for="(item, index) in pagedata.data_items" :key="index">
                      <td class="border-b py-2 border-r">{{ index + 1 }}</td>
                      <td class="border-b py-2 border-r">{{ item.purchase_code }}</td>
                      <td class="border-b py-2 border-r">{{ item.product_name }}</td>
                      <td class="border-b py-2 border-r">{{ item.description }}</td>
                      <td class="border-b py-2 border-r">{{ item.product_type == 1 ? 'Poly' : 'MBB' }}</td>
                      <td class="border-b py-2 border-r">{{ $h.myFloat(item.purchase_qty) }} {{ item.different_uqc == 1 ? item.second_uqc_name : item.first_uqc_name }}</td>
                      <td class="border-b py-2 border-r">{{ item.failed_qty ? $h.myFloat(item.failed_qty) : '-' }}</td>
                      <td class="border-b py-2 border-r">{{ item.cr_qty ? $h.myFloat(item.cr_qty) : '-' }}</td>
                      <td class="border-b py-2 border-r">{{ item.cr_qty_paid ? $h.myFloat(item.cr_qty_paid - (item.cr_qty_ooc_pending ? item.cr_qty_ooc_pending : 0)) : '-' }}</td>
                      <td class="border-b py-2 border-r">{{ item.cr_qty_ooc_pending ? $h.myFloat(item.cr_qty_ooc_pending) : '-' }}</td>
                      <td class="border-b py-2 border-r">{{ item.cr_qty_pending ? $h.myFloat(item.cr_qty_pending) : '-' }}</td>
                      <td class="border-b py-2 border-r">{{ item.cr_qty_duty_request ? $h.myFloat(item.cr_qty_duty_request) : '-' }}</td>
                      <td class="border-b py-2 border-r">{{ item.hold_qty ? $h.myFloat(item.hold_qty) : '-' }}</td>
                      <td class="border-b py-2 border-r">{{ item.hold_qty_paid ? $h.myFloat(item.hold_qty_paid - (item.hold_qty_ooc_pending ? item.hold_qty_ooc_pending : 0)) : '-' }}</td>
                      <td class="border-b py-2 border-r">{{ item.hold_qty_ooc_pending ? $h.myFloat(item.hold_qty_ooc_pending) : '-' }}</td>
                      <td class="border-b py-2 border-r">{{ item.hold_qty_pending ? $h.myFloat(item.hold_qty_pending) : '-' }}</td>
                      <td class="border-b py-2 border-r">{{ item.hold_qty_duty_request ? $h.myFloat(item.hold_qty_duty_request) : '-' }}</td>
                      <td class="border-b py-2 border-r">{{ item.delivered_qty ? $h.myFloat(item.delivered_qty) : '-' }}</td>
                      <td class="border-b border-r p-0">
                        <span v-if="item.request_details.length > 0">
                          <table>
                            <tr class="bg-theme-14">
                              <th class="p-2 border dark:border-dark-5 whitespace-nowrap w-40">OW NO</th>
                              <!-- <th class="p-2 border dark:border-dark-5 whitespace-nowrap w-40">Party</th> -->
                              <th class="p-2 border dark:border-dark-5 whitespace-nowrap w-40">Product</th>
                              <th class="p-2 border dark:border-dark-5 whitespace-nowrap w-40">Code</th>
                              <th class="p-2 border dark:border-dark-5 whitespace-nowrap w-40">Type</th>
                              <th class="p-2 border dark:border-dark-5 whitespace-nowrap w-40">Purchase Qty</th>
                              <th class="p-2 border dark:border-dark-5 whitespace-nowrap w-40">Invoice No</th>
                              <th class="p-2 border dark:border-dark-5 whitespace-nowrap w-40">Outward Date</th>
                              <th class="p-2 border dark:border-dark-5">Tax Details</th>
                            </tr>
                            <tr v-for="(sub_item,inx) in item.request_details" :key="inx">
                              <td class="border py-2 dark:border-dark-5 whitespace-nowrap w-40">{{ sub_item.request_code }}</td>
                              <!-- <td class="border py-2 dark:border-dark-5 whitespace-nowrap w-40">{{ sub_item.companey_name }}</td> -->
                              <td class="border py-2 dark:border-dark-5 whitespace-nowrap w-40">{{ sub_item.watt }}</td>
                              <td class="border py-2 dark:border-dark-5 whitespace-nowrap w-40">{{ sub_item.code }}</td>
                              <td class="border py-2 dark:border-dark-5 whitespace-nowrap w-40">{{ sub_item.product_type == 1 ? "Poly" : (sub_item.product_type == 2 ? "MBB" : "Both") }}</td>
                              <td class="border py-2 dark:border-dark-5 whitespace-nowrap w-40">{{ sub_item.quantity }}</td>
                              <td class="border py-2 dark:border-dark-5">{{ sub_item.invoice_no }}</td>
                              <td class="border py-2 dark:border-dark-5">{{ sub_item.outward_date }}</td>
                              <td class="border dark:border-dark-5 p-0">
                                <span class="p-0" v-if="sub_item.tax_details.length > 0">
                                  <table>
                                    <tr class="bg-theme-14">
                                      <th class="p-2 border dark:border-dark-5 whitespace-nowrap w-40">Duty Code</th>
                                      <th class="p-2 border dark:border-dark-5 whitespace-nowrap w-40">Quantity</th>
                                      <th class="p-2 border dark:border-dark-5 whitespace-nowrap w-40">Duty Amount</th>
                                      <th class="p-2 border dark:border-dark-5 whitespace-nowrap w-40">Ref NO</th>
                                      <th class="p-2 border dark:border-dark-5 whitespace-nowrap w-40">OOC NO</th>
                                      <th class="p-2 border dark:border-dark-5 whitespace-nowrap w-60">OOC Date</th>
                                    </tr>
                                    <tr v-for="(duty_item,inx) in sub_item.tax_details" :key="inx">
                                      <td class="border py-2 dark:border-dark-5 whitespace-nowrap w-40">{{ duty_item.duty_code }}</td>
                                      <td class="border py-2 dark:border-dark-5 whitespace-nowrap w-40">{{ (duty_item.duty_request_qty ? $h.myFloat(duty_item.duty_request_qty) : '-') }}</td>
                                      <td class="border py-2 dark:border-dark-5 whitespace-nowrap w-40">{{ (duty_item.duty_amount_request ? $h.myFloat(duty_item.duty_amount_request) : '-') }}</td>
                                      <td class="border py-2 dark:border-dark-5 whitespace-nowrap w-40">{{ duty_item.ref_no }}</td>
                                      <td class="border py-2 dark:border-dark-5 whitespace-nowrap w-40">{{ duty_item.ooc_no }}</td>
                                      <td class="border py-2 dark:border-dark-5 w-60">{{ duty_item.ooc_date }}</td>
                                    </tr>
                                  </table>
                                </span>
                                <span v-else>
                                 {{ sub_item.tax_details }}
                                </span>
                              </td>
                            </tr>
                          </table>
                        </span>
                        <span v-else>
                          -
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div v-if="pagedata.data_items.length == 0" class="text-md font-medium mr-auto text-center my-8">
                Records Not Found
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>

</template>

<script>
/* eslint-disable */
import { defineComponent, reactive,onMounted } from 'vue'
import axios from 'axios'
import { BASE_URL } from '@/constant/config.js'
import moment from 'moment'

export default defineComponent({
  setup() {
    const pagedata = reactive({
      data_items: [],
      product_details: [],
      material_details: [],
      loading: false,
    })
    const duty_filters = reactive({
      product_id: "0",
      material_id: "0",
      product_type: "0",
      purchase_type: "0",
      purchase_code: "",
      s_date:moment().startOf('month').format('YYYY-MM-DD'),
      e_date:moment().format('YYYY-MM-DD'),
    })
    const print_filters = reactive({
      product_id: "0",
      material_id: "0",
      product_type: "0",
      purchase_type: "0",
      purchase_code: "",
      s_date:moment().startOf('month').format('YYYY-MM-DD'),
      e_date:moment().format('YYYY-MM-DD'),
    })

    // Get Report Details
    const get_report_details = async () => {

      print_filters.product_id = duty_filters.product_id
      print_filters.material_id = duty_filters.material_id
      print_filters.product_type = duty_filters.product_type
      print_filters.purchase_type = duty_filters.purchase_type
      print_filters.purchase_code = duty_filters.purchase_code
      print_filters.s_date = duty_filters.s_date
      print_filters.e_date = duty_filters.e_date

      let params = {
        "product_id": print_filters.product_id == "0" ? "" : window.btoa(print_filters.product_id),
        "material_id": print_filters.material_id == "0" ? "" : window.btoa(print_filters.material_id),
        "product_type": print_filters.product_type == "0" ? "" : window.btoa(print_filters.product_type),
        "purchase_type": print_filters.purchase_type == "0" ? "" : window.btoa(print_filters.purchase_type),
        "purchase_code": print_filters.purchase_code == "" ? "" : window.btoa(print_filters.purchase_code),
        "s_date": print_filters.s_date == "" ? "" : window.btoa(print_filters.s_date),
        "e_date": print_filters.e_date == "" ? "" : window.btoa(print_filters.e_date)
      }

      pagedata.loading = true

      let promise = axios({
        url: "api/report/invoice-wise-outward-report",
        method: "POST",
        data: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
      .then(result => result.data)
      .then(data => {

        pagedata.data_items = data.items
        pagedata.loading = false

        sessionStorage.setItem('purchase_wise_outward_report',JSON.stringify(print_filters))

      })
      .catch(_error => {

        pagedata.data_items = []
        pagedata.loading = false

      });

    }

    // Get Material Details
    const productDetails = async () => {

      const params = {
      };

      let promise = axios({
        url: "api/master/dp_product",
        method: "POST",
        data: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
      .then(result => result.data)
      .then(data => {

        pagedata.product_details = data.items

      })
      .catch(_error => {

        pagedata.product_details = []

      });
    }

    // Get Material Details
    const materialDetails = async () => {

      const params = {
        product_id: duty_filters.product_id != "0" ? window.btoa(duty_filters.product_id) : ""
      };

      let promise = axios({
        url: "api/master/get_details_for_tax",
        method: "POST",
        data: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
      .then(result => result.data)
      .then(data => {

        pagedata.material_details = data.items

        let is_match = false
        pagedata.material_details.forEach(element => {
          if(element.id == duty_filters.material_id) {
            is_match = true
          }
        });

        if(is_match == false) {
          duty_filters.material_id = "0"
        }
      })
      .catch(_error => {

        duty_filters.material_id = "0"
        pagedata.material_details = []

      });
    }

    // On reset filter
    const resetStockFilters = () => {
      duty_filters.product_id = "0";
      duty_filters.material_id = "0";
      duty_filters.product_type = "0";
      duty_filters.purchase_type = "0";
      duty_filters.purchase_code = "";
      duty_filters.s_date =moment().startOf('month').format('YYYY-MM-DD')
      duty_filters.e_date =moment().format('YYYY-MM-DD')
    }

    onMounted(() => {

      let filter_items = sessionStorage.getItem('purchase_wise_outward_report') != null ? JSON.parse(sessionStorage.getItem('purchase_wise_outward_report')) : null

      if(filter_items != null) {
        duty_filters.product_id = filter_items.product_id
        duty_filters.material_id = filter_items.material_id
        duty_filters.product_type = filter_items.product_type
        duty_filters.purchase_type = filter_items.purchase_type
        duty_filters.purchase_code = filter_items.purchase_code
        duty_filters.s_date = filter_items.s_date
        duty_filters.e_date = filter_items.e_date

      }
      productDetails()
      materialDetails()
      get_report_details()
    })


    return {
      pagedata,duty_filters,print_filters,
      get_report_details,
      materialDetails,
      resetStockFilters,
    }
  }
})
</script>
